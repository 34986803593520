

import React, { useState, useEffect } from "react";

import Text2Title from "../Title/Text2Title";
import { Link, useNavigate } from "react-router-dom";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import MySkeleton from "../Loaders/MySkeleton";
import roundPrice from "../../utils/PricePipe"
const MegaDeals = () => {
  let navigation = useNavigate();
  const [getItems, setItems] = useState([]);
  const [titleThree, setTitleThree] = useState('');

  useEffect(() => {
    const getAllCartProduct = async () => {
      try {
        
        let url = ApiNames.getShowProducts
        const response = await Axios.get(
          `${url}`
        );


        setItems(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCartProduct();
  }, []);


  function addToCarts(product) {
    // navigation(`/ProductView/${id}`)
    let splitName =product.title.replace(/\s+/g, "-");

    navigation(`/ProductView/${splitName}/${product._id}`)
  }

  useEffect(() => {
    const getMoreReason = async () => {
      try {
        let api = ApiNames.getTitles
        const response = await Axios.get(
          `${api}`
        );
        response.data.forEach((element) => {

          if (element.index === '3') {
            setTitleThree(element.title)
          }
        });

      } catch (error) {
        console.log(error);
      }
    };
    getMoreReason();
  }, []);

  return (
    <>
    {getItems.length >0 ? (
 <div className="MegaDeals-cards">
 <Text2Title text={titleThree} />
 <div className="row g-3">
   {getItems.map((products, index) => (
     <Link to={`/ProductView/${products?.firstDetail.title.replace(/[\s/]+/g, "-")}/${products?.firstDetail._id}`} style={{textDecoration:'none'}} key={index} className="col-lg-6">
       <div  className="card MegaDeals-top">
         <span className="card-topLeft-text">{products?.firstDetail?.categoryName}</span>
         <div className="row g-0">
           <div className="col-5">
             <div className="deals-topimage">
               <img
                 src={products?.firstDetail?.thumbnail}
                 className="img-fluid rounded-start deals-image"
                 alt="..."
               />
             </div>
           </div>
           <div className="col-7">
             <div className="card-body card-right">
               <h5 className="card-title">{products?.firstDetail?.title}</h5>
               <p className="card-text-priceTwo">
                 AED <span>{roundPrice(products?.firstDetail?.marketPrice)}</span>
               </p>
               <p className="card-text-price">
                 AED <span>{roundPrice(products?.firstDetail?.sellingPrice)}</span>
               </p>
             </div>
           </div>
         </div>
       </div>
     </Link>
   ))}
 </div>



</div>
    ):(
      <MySkeleton count={1} height={300} width={500}/>
    )}
   
    </>
  );
};

export default MegaDeals;
