import React, { useEffect, useState } from 'react'
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import Toaster from "../../utils/Toaster";
import { Navigate, useNavigate } from "react-router-dom";
import { useCart } from '../../Context/CartContext';
import SimilerProducts from '../../Component/SimilerProducts/SimilerProducts';
import Pbrands from '../popularBrands/popularBrands';
const CartItems = () => {
    let navigation = useNavigate();
    const [getWishCount, setWishCount] = useState(0);
    const [getcartCount, setCartCount] = useState(0);
    const [getOverallStatus, setOverallStatus] = useState(false);
    const [getcartGrandTotal, setCartGrandTotalCount] = useState(0);
    const [getshippingCharges, setshippingCharges] = useState(0);
    const [ShippingMethod, SetShippingMethod] = useState(0);
    const [getDiscount, setDiscount] = useState(0);
    const [getCouponError, setCouponError] = useState('');
    const [getTaxCharges, setTaxCharges] = useState(0);
    const [getFinalCharges, setFinalCharges] = useState(0);
    const [getcarItems, setCartItems] = useState([]);
    const navigate = useNavigate();
    const { cartItems, setSelectRefresh, selectRefresh, addToCart, whenLoginRefreshProduct,
        removeFromCart } = useCart()


    useEffect(() => {
        const cartItems = async () => {

            let api = ApiNames.user_getWishListCount
            try {
                const response = await Axios.get(`${api}`);
                if (response.data.cartCount <= 0) {
                    navigation(`/landing`)
                    return
                }

                console.log(response.data.cartItemDetails)
                setCartItems(response.data.cartItemDetails)
                setOverallStatus(response?.data?.overallStatus)
                setCartGrandTotalCount(response.data.grandTotal)
                setshippingCharges(response.data.shippingCharges)
                SetShippingMethod(response.data.shippingMethod)
                setDiscount(response?.data?.discount)
                setTaxCharges(response.data.totalTaxPrice)
                setFinalCharges(response.data.finalTotal)
            } catch (error) {
                console.error('Error:', error);
            }
        }
        cartItems()
    }, [cartItems])
    function gotoHome() {
        navigate(`/landing`)
    }
    async function addToCartsProduct(productId, type, DeliveryType) {
        try {

            let api = ApiNames.addToCart;
            let body = {
                productId: productId,
                type: type,
                DeliveryType: DeliveryType
            };
            const response = await Axios.post(
                `${api}`, body
            );

            addToCart(response.data.message)
        } catch (error) {
            console.log(error);
            Toaster(error.response.data.error)
        }
    }
    function addToCarts(product) {
        debugger
        // navigate(`/ProductView/${id}`)
        let splitName =product.productDetails[0]?.title.replace(/\s+/g, "-");
        navigate(`/ProductView/${splitName}/${product.productDetails[0]?._id}`)
    }
    function landing() {
        navigate(`/landing`)
    }
    function gotoCheckout() {
        navigate(`/CHeckout`)
    }

    function fixtheNuber(data) {
        if (data !== undefined && data !== null) {
          const numericData = parseFloat(data); // Convert input string to a number
          if (!isNaN(numericData)) { // Check if the conversion was successful
            const roundedValue = Math.round(numericData); // Round to nearest integer
            return roundedValue; // Format to two decimal places
          }
        }
        return ''; // Or any other default value or behavior you prefer
      }

    async function submitCoupon(type) {
        try {

            let api = ApiNames.applyCoupon
            const response = await Axios.post(
                `${api}`, { type: type }
            );
            addToCart('')
            // alert(response?.data?.message)

            setCouponError(response?.data?.message)
            // Toaster(response?.data?.message)
        } catch (err) {
            setCouponError(err?.response?.data?.message)
            // Toaster(err?.response?.data?.error)
        }
    }
    return (
        <div className="shipping-address">

            <div className="container ">
                <p className='HomeNavigation'><span onClick={gotoHome} className='navTOnext'> Home</span> / <span className='navTOnext'>View cart</span></p>
                <p className='HomeNavigation'>There are {getcarItems?.length} products in your cart</p>
                <div className="row">
                    <div className="col-md-8">
                        <div className="purchase-address">
                            {/* <p className="purchase-top-line">
                                Complete your order and earn
                                <span className="top-line">7,680 GM Global Points </span> for a
                                discount on a future purchase
                            </p> */}

                            {getcarItems.map((value, index) => (
                                <div key={index} className="andriod-folding">
                                    <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-lg-9">
                                            <div className="folding-image-line">
                                                <div onClick={() => addToCarts(value)} className="image-leftt"> <img
                                                    src={value.productDetails[0]?.thumbnail}
                                                    className="andriod-img" alt="..."
                                                /></div>

                                                <div onClick={() => addToCarts(value)} className="andriod-folding-para">
                                                    <p className="folding-line">{value.productDetails[0]?.title}
                                                    </p>
                                                    <p className="folding-line">Delivery Type : {value?.deliveryTypeDetails[0]?.name}
                                                    </p>
                                                    <p className="folding-cost">{fixtheNuber(value?.totalPrice)} AED</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-lg-3">
                                            <div className="folding-cost-button">
                                                <div className=" folding-grid">
                                                    <div className="row">
                                                        {value.quantity > 1 ? (
                                                            <div onClick={() => addToCartsProduct(value.productId, 0, value.DeliveryType)} style={{ cursor: 'pointer' }} className="col">
                                                                <img style={{ width: "20px", height: "20px" }} src='/images/minus.svg' alt='no-data' />
                                                            </div>
                                                        ) : (

                                                            <div onClick={() => addToCartsProduct(value.productId, -1, value.DeliveryType)} style={{ cursor: 'pointer' }} className="col">
                                                                {/* <i className="fas fa-trash-alt deleteIcons"></i> */}
                                                                <img src="/images/LandingPg/deleteIcons.svg" className="IconsImgsDelete" />
                                                            </div>
                                                        )}
                                                        <div className="col">{value?.quantity}</div>
                                                        <div onClick={() => addToCartsProduct(value.productId, 1, value.DeliveryType)} style={{ cursor: 'pointer' }} className="col">
                                                            <img style={{ width: "20px", height: "20px" }} src='/images/plus.svg' alt='no-data' />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <button onClick={() => addToCartsProduct(value.productId, -1)} className="folding-button">    <img src="/images/LandingPg/deleteIcons.svg" className="IconsImgsDelete" /></button> */}
                                            </div>
                                        </div>
                                        <p className='outOfStock'>{value?.message}</p>
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="continue-shopping">
                            {/* <div className="shipping-simbol">
                                <a onClick={landing} className="shipping-goto-page" >
                                    Continue Shopping
                                    <i className="fas fa-arrow-right right-arrow-page"></i>
                                </a>
                            </div> */}
                            <p className='orderSummury'>Order Summary</p>
                            <div className="cart-totals">
                                {/* <h2 className="cart-heading">Cart Totals</h2> */}
                                <div className="subtotal">
                                    <p className="subtotal-line">Subtotal</p>
                                    <p className="subtotal-cost">AED {getcartGrandTotal}</p>
                                </div>
                                {/* <div className="subtotal">
                                    <p className="subtotal-line">Tax</p>
                                    <p className="subtotal-cost">AED {getTaxCharges}</p>
                                </div> */}
                                <div className="subtotal">
                                    <p className="subtotal-line">Shipping ({ShippingMethod})</p>
                                    <p className="subtotal-cost">AED {getshippingCharges}</p>
                                </div>
                                <div className="subtotal subtotalLine ">
                                    <p className="subtotal-line">Coupon discount
                                        {getDiscount <= 0 ? (
                                            null
                                        ) : (
                                            <span onClick={() => submitCoupon(0)}><i style={{ color: 'red', cursor: 'pointer', paddingLeft: '5px' }} className="fas fa-times-circle"></i></span>

                                        )}
                                    </p>
                                    {getDiscount <= 0 ? (
                                        <p className="subtotal-cost">AED {getDiscount}</p>

                                    ) : (
                                        <p style={{ color: 'green' }} className="subtotal-cost">AED -{getDiscount}</p>

                                    )}
                                </div>
                                <div className="subtotal subtotalLineNew">
                                    <p className="subtotal-line">Grand Total (Inclusive of VAT)</p>
                                    <p className="subtotal-cost">AED {getFinalCharges}</p>
                                </div>
                                {getOverallStatus ? (
                                    <div className="proceed-button">
                                        <button onClick={gotoCheckout} className="proceed-checkout-button">
                                            Proceed to Checkout
                                        </button>
                                    </div>
                                ) : (
                                    <div className="proceed-button">
                                        <button className="proceed-checkout-buttons">
                                            Product is out of stock
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <SimilerProducts />

                <Pbrands />
            </div>
        </div>
    )
}

export default CartItems