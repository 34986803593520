import React, { useEffect, useRef, useState } from "react";

import TextTitle from "../../Component/Title/TextTitle";
import CategoryCard from "../../Component/categoryCard/CategoryCard";
// import { Button } from "bootstrap";
import Axios from '../../utils/AxiosConfi';
import Slider from "../../Component/Slider/Slider";
import Productslidr from "../../Component/Productslidr/Productslidr";
import ShpMoreCard from "../../Component/ShopMore/ShpMoreCard";
import MegaDeals from "../../Component/MegaDeals/MegaDeals";
import MobileBanner from "../../Component/Banners/MobileBanner";
import BiggerBanner from "../../Component/Banners/BiggerBanner";
import MdBanerTop from "../../Component/Banners/MDBanerTop";
import ApiNames from "../../Constants/ApiUrls";
// import ProductView from "../../Component/ProductView/ProductView";
// import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProductItems from "../../Component/ProductItems/ProductItems";
import { useCart } from "../../Context/CartContext";
import Pbrands from "../popularBrands/popularBrands";
import RecommendedProducts from "../../Component/RecommendedProducts/RecommendedProducts";
import Welcome from "../../Component/Loaders/Welcome";
const Landing = () => {
  const [getItems, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [bestProducts, setBestProducts] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [titleOne, setTitleOne] = useState('');
  const [titleTwo, setTitleTwo] = useState('');
  const [getIsLoader, SetIsLoader] = useState(true);
  const [titleThree, setTitleThree] = useState('');
  const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart()
  let navigation = useNavigate();

  // useEffect(()=>{
  //   const loaders = async () => {
  //     setTimeout(()=>{

  //     },2000)
  //   };
  //   return () => loaders();
  // })

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const getMoreReason = async () => {
      try {

        let api = ApiNames.getTitles
        const response = await Axios.get(
          `${api}`
        );
        if (response && response.data) {
          response.data.forEach((element) => {
            if (element.index === '1') {
              setTitleOne(element.title)
            }
            if (element.index === '2') {
              setTitleTwo(element.title)
            }
            if (element.index === '3') {
              setTitleThree(element.title)
            }
          });
        }
        else {
          setErrorMessage('Invalid response from the server.');
        }

      } catch (error) {
        setErrorMessage('Failed to fetch titles. Please try again later.');
      }
    };
    getMoreReason();
  }, []);
  // useEffect(() => {
  //   const getMoreReason = async () => {
  //     try {
  //       let api = ApiNames.getCategoryWiseProducts
  //       const response = await Axios.get(
  //         `${api}`
  //       );

  //       setItems(response.data);

  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getMoreReason();
  // }, [cartItems]);
  useEffect(() => {
    const getAllCartProduct = async () => {
      try {
        let api = ApiNames.Product_Newarrivels
        const response = await Axios.get(
          `${api}`
        );
        setTimeout(() => {
          SetIsLoader(false)
        }, 800)
        setBestProducts(response.data.newArrivals);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCartProduct();
  }, [cartItems, selectRefresh]);


  // const getBlogs = async () => {
  //   const url = ApiNames.getBlogs;
  //   try {
  //     // Introducing a delay of 3 seconds for demonstration purposes
  //     await new Promise(resolve => setTimeout(resolve, 0));

  //     const response = await Axios.get(url);
  //     setBlogsData(response);
  //   } catch (error) {
  //     console.log(error);
  //   } 
  // };
  useEffect(() => {
    const getBlogs = async () => {
      try {
        let api = ApiNames.getBlogs
        const response = await Axios.get(
          `${api}`
        );

        setBlogsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBlogs();
  }, [selectRefresh]);


  const handleDataReturn = (data) => {
  };


  return (
    <div className="landingPage-data">
      {getIsLoader === true ? (
        <div>
          <Welcome />
        </div>
      ) : (


        <div>
          <div className="">
            <Productslidr />
          </div>

          <div className="categoris-section">
            <div className="container">
              <TextTitle text={titleOne} />

              <CategoryCard />
              <div className="sectionsCard">

                <div className="row g-3">
                  <div className="col-md-6">
                    <ShpMoreCard />
                  </div>
                  <div className="col-md-6">
                    <MegaDeals />
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <Slider />
              </div>

            </div>
          </div>
          <div className="container">
            <TextTitle text="New Arrivals" className="textSub-title textSub-title1" />
            <ProductItems productObj={bestProducts} />







            {/* <div>
          {getItems.map(
            (value, index) =>
              value.category.subcategories.length >= 1 && ( // Check if the category index is above or equal to 1
                <div key={index}>
                  <TextTitle
                    text={value.category?.categoryName}
                    className="textSub-title"
                  />
                  <div className="row  g-3">
                    <div className="col-md-5">
                      <BiggerBanner
                        image={value.category?.subcategories[0]?.subThumbnail}
                        name={value.category?.subcategories[0]?.subCategoryName}
                        id={value.category?.subcategories[0]?._id}
                      />
                    </div>
                    <div className="col-md-7">
                      <div className="row mb-4 g-3">
                        {value.category?.subcategories
                          .slice(1)
                          .map((item, subIndex) => (
                            <div key={subIndex} className="col-md-4 col-6">
                              <MobileBanner
                                image={item?.subThumbnail}
                                name={item?.subCategoryName}
                                id={item._id}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {value.bestSellingProducts.length > 0 && (
                    <div>
                      <TextTitle
                        text="Best sellers "
                        className="textSub-title"
                      />
                      <ProductItems productObj={value.bestSellingProducts} />
                    </div>
                  )}
                  {value.getPriceDropProducts.length > 0 && (
                    <div>
                      <TextTitle
                        text="Price Dropped Products "
                        className="textSub-title"
                      />
                      <ProductItems productObj={value.getPriceDropProducts} />
                    </div>
                  )}

                  {value.CuratedProducts.length > 0 && (
                    <div>
                      <TextTitle
                        text="Curated Products "
                        className="textSub-title"
                      />
                      <ProductItems productObj={value.CuratedProducts} />
                    </div>
                  )}
                </div>
              )
          )}
        </div> */}

            {/* <ProductView /> */}
          </div>
          <div className="container">
            <MdBanerTop />
            <RecommendedProducts />
          </div>

          <div className="container">
            {/* <div className="reward-box-top">
              <p className="reward-title">Reward</p>
              <p className="reward-text">Elevate your business with top-quality signage, advertising, and LED solutions from Flip Deals. As the leading one-stop-shop for all your needs, we have over 20 distribution centers and warehouses across the GCC region, including the UAE, Saudi Arabia, Oman, Kuwait, Qatar, and Bahrain. Whether you’re in construction, fabrication, interior design, or exhibitions, we offer the right products and services to help you stay ahead of the competition.</p>
            </div> */}
            <div className="blog-top">
              <h3 className="Blog-title">Blog</h3>
              <p className="blog-subtitle">Stay informed with engaging articles</p>

              <div className="row">
                {blogsData.slice(0, 4).map((products, index) => (
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/bloginfo/${products.title?.replace(/\s+/g, '-')}/${products._id}`}
                    key={index}
                    className="col-md-3"
                  >
                    <div className="card blog-card" >
                      <img src={products?.image} className="card-img-top blog-image" alt="..." />
                      <div className="card-body blog-body">

                        <p className="card-text blog-text">
                          {products?.title.slice(0, 55)}
                        </p>

                      </div>
                    </div>
                  </Link>
                )
                )}
              </div>

            </div>

          </div>

          <Pbrands />
        </div>

      )}
    </div>
  );
};

export default Landing;
