import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import { Link, useNavigate } from "react-router-dom";
import MySkeleton from "../Loaders/MySkeleton";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const Slider = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [getBanner, setBanner] = useState([]);
  let navigation = useNavigate();
  const buttonRef = useRef(null);
  useEffect(() => {
    // Fetch banner data
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getBannerImages;
        const response = await Axios.get(url);
        const sectionData = response.data.filter(value => value.sectionId === 2);
        setBanner(sectionData);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);

  useEffect(() => {
    // Attach event listeners to custom buttons once Swiper instance is available
    const handleNextButtonClick = () => {
      if (swiperInstance) swiperInstance.slideNext();
    };
    const handlePrevButtonClick = () => {
      if (swiperInstance) swiperInstance.slidePrev();
    };

    const nextButton = document.getElementById('customNextButton');
    const prevButton = document.getElementById('customPrevButton');

    if (nextButton) {
      nextButton.addEventListener('click', handleNextButtonClick);
    }
    if (prevButton) {
      prevButton.addEventListener('click', handlePrevButtonClick);
    }

    return () => {
      if (nextButton) {
        nextButton.removeEventListener('click', handleNextButtonClick);
      }
      if (prevButton) {
        prevButton.removeEventListener('click', handlePrevButtonClick);
      }
    };
  }, [swiperInstance]); // Dependency on `swiperInstance`

  function gotoSection(data) {
    if (data.bannerType === "Product") {
      navigation(`/ProductView/${data.categoryId}`);
    } else {
      navigation(`/Filter/1/${data.categoryId}`);
    }
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.click(); // Trigger the click event
      }
    }, 2500);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  return (
    <>
      {getBanner.length > 0 ? (
        <div className="bottom-Banner">
          <p className="slider-topTitle">In Focus</p>
          <Swiper
          
            onSwiper={setSwiperInstance} // Set swiper instance
            navigation={false}
            className="mySwiper"
          >
            {getBanner.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  to={
                    item.bannerType === "Product"
                      ? `/ProductView/product/${item.categoryId}`
                      : `/Filter/${item.categoryName?.replace(/\s+/g, '-')}/1/${item.categoryId}`
                  }
                  className="banner-imageTop"
                >
                  <img className="banner-images" src={item.image} alt="no-imge" />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Navigation Buttons */}
          <button id="customPrevButton">
            <i className="fas fa-angle-left"></i>
          </button>
          <button ref={buttonRef} id="customNextButton">
            <i className="fas fa-angle-right"></i>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Slider;
