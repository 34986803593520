import React, { useEffect, useState } from 'react'
import Form from "react-bootstrap/Form";
import ApiNames from "../../Constants/ApiUrls";
import Axios from "../../utils/AxiosConfi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from '../../utils/Toaster';
import { useCart } from '../../Context/CartContext';
import { useNavigate } from 'react-router-dom';
const Checkout = () => {
    let navigation = useNavigate();
    const [getOverallStatus, setOverallStatus] = useState(false);
    const [getUserProfule, setUserProfile] = useState([]);
    const [getUserAddress, setUserAdress] = useState([]);
    const [getCouponDetails, setCouponDetails] = useState([]);
    const [getisAddress, setIsAddress] = useState(true);
    const [getFlag, setFlag] = useState('');
    const { cartItems, selectRefresh, addToCart, removeFromCart } = useCart()
    const [getNumber, setsetNumber] = useState('');
    const [getcountryList, SetcountryList] = useState([]);
    const [mobileNumber, setPhoneNumber] = useState('');
    const [getMblNumber, setMblNumber] = useState('');
    const [getCountryCode, setCountryCode] = useState('');
    const [getCouponError, setCouponError] = useState('');
    const [getCouponStatus, setCouponStatus] = useState(null);
    const [ShippingMethod, SetShippingMethod] = useState(0);
    const [getcartGrandTotal, setCartGrandTotalCount] = useState(0);
    const [getAddressType, setAddressType] = useState('');
    const [selectedOption, setSelectedOption] = useState('Home');
    const [otherText, setOtherText] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [getName, setName] = useState('');
    const [getAddressId, setSelectedAddressId] = useState([]);
    const [finalAmount, setFinalAmount] = useState([]);
    const [getCountry, setCountry] = useState([]);
    const [discount, setDiscount] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        const getProfileDetails = async () => {
            try {

                let api = ApiNames.profile
                const response = await Axios.get(
                    `${api}`
                );
                getAddress()
                setUserProfile(response.data)
                setFlag(response.data)
                setPhoneNumber(response?.data?.profile?.mobile);
                setMblNumber(response?.data?.profile?.mobile);
                setsetNumber(response.data?.profile?.countryCode)
                setCountryCode(response.data?.profile?.countryCode)
            } catch (error) {
                console.error("Error checking login status:", error);
            }
        }
        getProfileDetails()
    }, [])

    useEffect(() => {
        const getProfileDetails = async () => {
            try {

                let api = ApiNames.Inssearch
                const response = await Axios.post(
                    `${api}`, { name: getName }
                );

                SetcountryList(response?.data)
                setPhoneNumber(response?.data?.profile?.mobile)
            } catch (error) {
                console.error("Error checking login status:", error);
            }
        }
        getProfileDetails()
    }, [getName])

    async function getAddress() {
        try {
            try {

                let api = ApiNames.getAddress
                const response = await Axios.get(
                    `${api}`
                );

                // let address = []
                // let info = address.push(response.data)
                setUserAdress(response.data)
                // if (response.data.length <= 0) {
                //     setIsAddress(false)
                // }
                // else {
                //     setIsAddress(true)
                // }
            } catch (error) {
                console.error("Error checking login status:", error);
            }
        } catch {

        }
    }
    const handlePhoneNumberChange = event => {
        const modifiedNumber = event.target.value
        setMblNumber(event.target.value)
    };
    const AddAddresCOndition = () => {
        setIsAddress(false)
    }
    const handleKeyUp = (event) => {
        setName(event.target.value)
    }
    function GetCode(value) {
        setFlag(value)
        setsetNumber(value?.callingCode)
        setCountryCode(value?.callingCode)
    }

    const handleDiscountChange = (event) => {
        setDiscount(event.target.value.toUpperCase());
    };

    async function submitCoupon(type) {
        try {

            let api = ApiNames.applyCoupon
            const response = await Axios.post(
                `${api}`, { coupon: discount, type: type }
            );

            removeFromCart('')
            setDiscount('')
            addToCart('')
            // alert(response?.data?.message)

            setCouponError(response?.data?.message)
            setCouponStatus(response?.data?.type)
            // Toaster(response?.data?.message)
        } catch (err) {
            setCouponError(err?.response?.data?.message)
            // Toaster(err?.response?.data?.error)
        }
    }


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        country: '',
        address: '',
        appartment: '',
        city: '',
        state: '',
        pincode: '',
        emailId: '',
        saveShipping: false
    });

    const handleCountryChange = (e) => {
        setFormData({
            ...formData,
            country: e.target.value // Update the country in the form data state
        });
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    function constrinNumbers(number) {
        const formattedPrice = typeof number === 'number' ? number.toFixed(2) : '0.00';
        return formattedPrice
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            let api = ApiNames.createAddress
            formData.countryCode = getCountryCode
            formData.mobileNumber = getMblNumber
            if (selectedOption !== "Other") {
                formData.type = selectedOption
            }
            if (selectedOption === "Other") {
                formData.type = getAddressType
            }


            const response = await Axios.post(
                `${api}`, formData
            );
            const notify = () => toast(response.message);
            setIsAddress(true)
            getAddress()
            notify()

        } catch (error) {

            toast(error?.response?.data?.error);
            console.error("Error checking login status:", error);
        }




    };

    function setSelectedAddressIdFun(data) {
        console.log(data)
        setSelectedAddressId(data)
    }


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }


    async function displayRazorpay() {

        if (getAddressId.length <= 0) {
            Toaster("Select Shipping Address")
            return
        }

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        let authToken = localStorage.getItem('token')

        let requiredData = {
            price: 0,
            userId: '',
            address: getAddressId,
            allItems: '',
            quantity: ''
        }

        let api = ApiNames.getOrderByUser
        const result = await Axios.post(`${api}`, requiredData, {
            headers: {
                'authorization': authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;
        let reciptNumber = result.data.receipt
        const options = {
            key: "rzp_test_p2Rux4hXlsfpIs", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Flip Deals",
            description: "Test Transaction",
            image: '/images/fav.svg',
            order_id: order_id,
            handler: async function (response) {

                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    reciptNumber: reciptNumber,
                    cartItemsdata: ''
                };
                let api = ApiNames.Success
                const result = await Axios.post(`${api}`, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                if (result.data.status === true) {
                    navigation(`/Orders`)
                    addToCart('')
                    console.log('payment done')
                }
            },
            prefill: {
                name: `${getAddressId.firstName} ${getAddressId.lastName}`,
                email: `${getAddressId.emailId}`,
                contact: `${getAddressId.countryCode}` - `${getAddressId.mobileNumber}`,
            },
            notes: {
                firstName: `${getAddressId.firstName}`,
                lastName: `${getAddressId.lastName}`,
                address: `${getAddressId.address}`,
                appartment: `${getAddressId.appartment}`,
                city: `${getAddressId.city}`,
                state: `${getAddressId.state}`,
                pincode: `${getAddressId.pincode}`,
                emailId: `${getAddressId.emailId}`,
                countryCode: `${getAddressId.countryCode}`,
                mobileNumber: `${getAddressId.mobileNumber}`,
                type: `${getAddressId.type}`,
            },
            theme: {
                color: "#02adc7",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }


    // getOrderByCash
    async function continueToPay() {
        if (getAddressId.length <= 0) {
            Toaster("Select Shipping Address")
            return
        }

        let authToken = localStorage.getItem('token')

        let requiredData = {
            price: 0,
            userId: '',
            address: getAddressId,
            allItems: '',
            quantity: ''
        }

        let api = ApiNames.getOrderByCash
        const result = await Axios.post(`${api}`, requiredData, {
            headers: {
                'authorization': authToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        else {
            console.log('booked successfully')
            navigation(`/Orders`)
            addToCart('')
            console.log('payment done')
        }

    }
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'Other') {
            setShowInput(true);

        } else {
            setShowInput(false);
        }
    };

    const handleInputChange = (event) => {
        setAddressType(event.target.value)
        setOtherText(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${ApiNames.getAllCountries}`);
                setCountry(response.data)
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {

        cartItemss()
    }, [cartItems])
    async function cartItemss() {

        let api = ApiNames.user_getWishListCount
        try {

            const response = await Axios.get(`${api}`);
            if (response.data.cartCount <= 0) {
                navigation(`/landing`)
                return
            }

            setCartGrandTotalCount(response.data.grandTotal)
            setOverallStatus(response?.data?.overallStatus)
            const getcartGrandTotal = parseInt(response.data.finalTotal);
            const gstRate = 0.18; // 18% GST rate
            const shippingRate = 0.05; // 5% shipping rate

            // Calculate GST amount
            const gstAmount = parseInt(response.data.totalTaxPrice);

            // // Calculate shipping charges
            const shippingCharges = parseInt(response.data.shippingCharges);

            // // Calculate total amount including GST and shipping charges
            const totalAmount = 0;
            setCouponDetails(response.data.couponDetails)
            let makeObj = {
                gstAmount: gstAmount,
                shippingCharges: shippingCharges,
                totalAmount: getcartGrandTotal,
                discount: response?.data?.discount,
                shippingMethod: response?.data?.shippingMethod
            }
            if (response?.data?.couponDetails) {

            }
            setFinalAmount(makeObj)

        } catch (error) {
            console.error('Error:', error);
        }
    }

    async function checkoutPaymentHere() {
        let Session_id = ''
        setIsDisabled(true);
        try {

            if (getAddressId.length <= 0) {
                Toaster("Select Shipping Address")
                setIsDisabled(false);
                return
            }
            let api = ApiNames.paymentIntiation
            let sendObj = {
                addressId: getAddressId
            }
            const response = await Axios.post(`${api}`, sendObj);
            console.log(response.data)
            Session_id = response.data?.id
        } catch (err) {
            setIsDisabled(false);
        }
        setTimeout(() => {
            window.Checkout.configure({
                session: {
                    id: Session_id
                }
            });
            window.Checkout.showPaymentPage()
            // window.Checkout.showEmbeddedPage('#embed-target')
        }, 1000)
    }


    async function paywithNetwork() {
        let Session_id = ''
        setIsDisabled(true);
        try {

            if (getAddressId.length <= 0) {
                Toaster("Select Shipping Address")
                setIsDisabled(false);
                return
            }
            let api = ApiNames.paymentInitiationWithNetwork
            let sendObj = {
                addressId: getAddressId
            }
            const response = await Axios.post(`${api}`, sendObj);
            console.log(response.data)
            
            window.location.href = response.data?.paymentLink;
        } catch (err) {
            setIsDisabled(false);
        }
     
    }



    // Function to show the payment page
    const showPaymentPage = () => {
        window.Checkout.showPaymentPage();
    };


    const gotoNav = (data) => {
        navigation(data)
    }

    return (
        <div className="payement-page">



            <div className=" container">
                <div className="modal-bodyy ">
                    <p className="checkout"><span onClick={() => gotoNav('/landing')}>Home</span> <span onClick={() => gotoNav('/CartItems')}>/ Cart /</span> <span className="active-page">Checkout</span> </p>
                    {/* <p className="purchase-top-line">
                        Complete your order and earn
                        <span className="top-line">7,680 GM Global Points </span> for a
                        discount on a future purchase
                    </p> */}
                    <div className="row">
                        <div className="col-md-6">

                            <div className="left-contact-foorm">
                                <div className="left-address-box">
                                    <div className="contct-loginBelow">
                                        <h2 className="adress-titlees">Contact</h2>
                                    </div>
                                    <div className="register-number-header">
                                        <div className="registration-flag">
                                            <div className="registration-number">
                                                <div className="dropdown numberBox-top">
                                                    <button
                                                        className="dropdown-toggle dropdown-flag-button"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src={`data:image/png;base64,${getFlag.flag}`}
                                                            alt=""
                                                            className="registration-img"
                                                        />
                                                        <p className="register-number">+{getNumber}</p>
                                                    </button>
                                                    <ul
                                                        className="dropdown-menu countryCode-Dmenu"
                                                        aria-labelledby="dropdownMenuButton1"
                                                    >
                                                        <li className="dropdown-flag-list search-box">

                                                            <div className="dropdowninsideimage">
                                                                <i className="fas fa-search registration-img"></i>

                                                                <input
                                                                    type="text"
                                                                    onKeyUp={handleKeyUp}
                                                                    className="form-control"
                                                                    placeholder="Select Country Name"
                                                                    id=""
                                                                    aria-describedby="Numberhelp"

                                                                />
                                                            </div>
                                                        </li>

                                                        {getcountryList.map((value, index) => (
                                                            <li key={index} onClick={() => GetCode(value)} className="dropdown-flag-list">
                                                                <a className="dropdown-item" >
                                                                    <img
                                                                        src={`data:image/png;base64,${value.flag}`}
                                                                        alt=""
                                                                        className="registration-image"
                                                                    />
                                                                    <span className="flag-span">{value?.name}</span>
                                                                </a>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </div>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control-input numberInput-box"
                                                placeholder="Please Enter The Mobile Number"
                                                id=""
                                                defaultValue={mobileNumber}
                                                onChange={handlePhoneNumberChange}
                                                aria-describedby="Numberhelp"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="email-inputBox">


                                        <p className="email-forTrack">
                                            Enter your mobile number for Tracking and Invoice
                                        </p>
                                    </div>
                                    {getisAddress === false ? (
                                        <form onSubmit={handleSubmit}>
                                            <div className="contact-details-box">
                                                <h2 className="adress-titlees">Shipping & Billing Details</h2>
                                                <div className="row">
                                                    <div className="col-6 pe-0">
                                                        <input
                                                            className="adressInput-boxes"
                                                            type="text"
                                                            placeholder="First Name"
                                                            name="firstName"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            className="adressInput-boxes"
                                                            type="text"
                                                            placeholder="Last Name"
                                                            name="lastName"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <input
                                                    className="adressInput-boxes"
                                                    type="text"
                                                    placeholder="Company name (optional)"
                                                    name="companyName"
                                                    value={formData.companyName}
                                                    onChange={handleChange}
                                                />
                                                <input
                                                    className="adressInput-boxes"
                                                    type="text"
                                                    placeholder="Address"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                />

                                                <Form.Select
                                                    value={formData.country}
                                                    onChange={handleCountryChange}
                                                    className="adressInput-boxes"
                                                    aria-label="Default select example"
                                                >
                                                    <option className="country-name">Country / Region *</option>

                                                    {getCountry.map((value, index) => (
                                                        // <li key={index} onClick={() => GetCode(value)} className="dropdown-flag-list">

                                                        // </li>
                                                        <option key={index} className="country-name" value={value.name}>{value.name}</option>

                                                    ))}


                                                </Form.Select>
                                                <input
                                                    className="adressInput-boxes"
                                                    type="text"
                                                    placeholder="(House no, Building, Street, Area)"
                                                    name="appartment"
                                                    value={formData.appartment}
                                                    onChange={handleChange}
                                                />
                                                <div className="row">
                                                    <div className="col-6 pe-0">
                                                        <input
                                                            className="adressInput-boxes"
                                                            type="text"
                                                            placeholder="City"
                                                            name="city"
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <input
                                                            className="adressInput-boxes"
                                                            type="text"
                                                            placeholder="State"
                                                            name="state"
                                                            value={formData.state}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 pe-0">
                                                        <input
                                                            className="adressInput-boxes"
                                                            type="number"
                                                            placeholder="PIN Code"
                                                            name="pincode"
                                                            value={formData.pincode}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <input
                                                    className="adressInput-boxes"
                                                    type="emailId"
                                                    placeholder="Email"
                                                    name="emailId"
                                                    value={formData.emailId}
                                                    onChange={handleChange}
                                                />
                                                {/* <div className="form-check chekbox-form">
                                                    <input
                                                        className="form-check-input left-chekBox"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault"
                                                        name="saveShipping"
                                                        checked={formData.saveShipping}
                                                        onChange={handleChange}
                                                    />

                                                    <div>



                                                    </div>


                                                    <label
                                                        className="form-check-label save-shipping"
                                                        htmlFor="flexCheckDefault"
                                                    >
                                                        Save Shipping Details
                                                    </label>
                                                </div> */}





                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="option"
                                                            value="Home"
                                                            checked={selectedOption === 'Home'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <label style={{ padding: 4 }}>Home</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="option"
                                                            value="Office"
                                                            checked={selectedOption === 'Office'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <label style={{ padding: 4 }}>Office</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="option"
                                                            value="Other"
                                                            checked={selectedOption === 'Other'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        <label style={{ padding: 4 }}>Other</label>
                                                    </div>
                                                    {showInput && (
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className='adressInput-boxes'
                                                                placeholder="Enter other text"
                                                                value={otherText}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    )}


                                                </div>








                                                <ul className='ButtonsGrp'>
                                                    <li className='btnSingle'>
                                                        <button onClick={handleSubmit} className="AddAddresCheckOut">Add Address</button>
                                                    </li>
                                                    <li className='btnSingle'>
                                                        <button type='button' onClick={() => setIsAddress(true)} className="AddAddresCheckOuts">Close</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    ) : (
                                        <div>
                                            <div className="adress-top">
                                                <h2 className="shipping-adress-title">Shipping & Billing Details</h2>
                                                <ul className="billing-adress-top list-unstyled">
                                                    {getUserAddress.map((adress, index) => (
                                                        <li key={index} className="adress-list-top">
                                                            <div className="form-check">
                                                                <input onChange={() => setSelectedAddressIdFun(adress)}
                                                                    className="form-check-input checkedRadio-inpuut
                  "

                                                                    type="radio"
                                                                    name="flexRadioDefault"
                                                                    id="flexRadioDefault1"
                                                                />
                                                                <label
                                                                    className="form-check-label user-Namee"
                                                                    htmlFor="flexRadioDefault1"
                                                                >
                                                                    {adress.firstName} {adress?.lastName}
                                                                </label>

                                                                <p className="adress-data">{adress?.address}</p>
                                                                <p className="adress-data">{adress?.appartment}</p>
                                                                <p className="adress-data">{adress?.city}</p>
                                                                <p className="adress-data">{adress?.state} - {adress?.pincode}</p>
                                                                <p className="adress-data">{adress?.emailId}</p>
                                                                <p className="adress-data">{adress?.countryCode}- {adress?.mobileNumber}</p>
                                                                <p className="adress-data">{adress?.country}</p>
                                                                <p className="adress-data">Type: {adress?.type}</p>
                                                            </div>
                                                        </li>

                                                    ))}


                                                </ul>
                                                <div onClick={AddAddresCOndition} className="form-check form-check-inline">

                                                    <label
                                                        className="form-check-label different-adress"
                                                        htmlFor="inlineCheckbox1"
                                                    >
                                                        + Add New Address
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                    }


                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="right-CartList">


                                <div className="coupon-section">
                                    <h1 className="applyCoupn">Apply coupons <span className="arow-right"> <i className="fas fa-angle-right"></i></span></h1>


                                    {finalAmount?.discount <= 0 ? (
                                        <div className="coupon-box">
                                            <input
                                                className="discount-box"
                                                type="text"
                                                placeholder="Discount Code or Gift Card"
                                                value={discount}
                                                onChange={handleDiscountChange}

                                            />
                                            <button onClick={() => submitCoupon(1)} className="applyBtn">APPLY COUPON</button>


                                        </div>

                                    ) : (
                                        <div className="coupon-box">
                                            <input
                                                className="discount-box"
                                                type="text"
                                                disabled
                                                placeholder="Discount Code or Gift Card"
                                                value={getCouponDetails?.discountCode}
                                                onChange={handleDiscountChange}

                                            />
                                            <button onClick={() => submitCoupon(0)} className="removeCouponBtn">REMOVE COUPON</button>


                                        </div>
                                    )}
                                    {getCouponStatus ? (
                                        <p style={{ color: 'green' }} className='couponError'>{getCouponError}</p>

                                    ) : (
                                        <p style={{ color: 'red' }} className='couponError'>{getCouponError}</p>

                                    )}
                                    <hr />
                                    <div className="priceSection">
                                        <h1 className="price-title">Subtotal</h1>
                                        <h1 className="amount-right">AED {getcartGrandTotal}</h1>
                                    </div>
                                    <div className="priceSection">
                                        <h1 className="price-title">Shipping Charge ({finalAmount?.shippingMethod})</h1>
                                        <h1 className="amount-right">AED {constrinNumbers(finalAmount.shippingCharges)}</h1>
                                    </div>
                                    {/* <div className="priceSection">
                                        <h1 className="price-title">Tax</h1>
                                        <h1 className="amount-right">AED {constrinNumbers(finalAmount.gstAmount)}</h1>
                                    </div> */}
                                    <div className="priceSection">
                                        <h1 style={{ display: 'flex', gap: '5px' }} className="price-title">Coupon discount
                                            {finalAmount?.discount > 0 ? (
                                                <div style={{ color: 'green' }}>({getCouponDetails?.discountCode})</div>
                                            ) : (
                                                null
                                            )}


                                        </h1>
                                        {finalAmount?.discount > 0 ? (
                                            <h1 style={{ color: 'green' }} className="amount-right">AED -{constrinNumbers(finalAmount?.discount)}</h1>

                                        ) : (
                                            <h1 className="amount-right">AED {constrinNumbers(finalAmount?.discount)}</h1>
                                        )}
                                    </div>
                                    <div className="priceSection">
                                        <h1 className="price-title">Total Price (Inclusive of VAT)</h1>
                                        <h1 className="amount-right total-price">AED {constrinNumbers(finalAmount.totalAmount)}</h1>
                                    </div>
                                </div>

                                {getOverallStatus ? (
                                    <div style={{ display: 'flex', gap: 2 }}>
                                        {/* <button onClick={displayRazorpay} className="applyCheckOut">Continue to Pay</button> */}
                                        <button disabled={isDisabled}
                                            onClick={paywithNetwork}
                                            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                            className="applyCheckOut">Continue to Pay</button>
                                        <button onClick={continueToPay} className="cashOnDelivery">Cash on Delivery</button>

                                    </div>
                                ) : (
                                    <button className="applyCheckOuts">Out of stock </button>
                                )}
                            </div>
                            <div id="embed-target"> </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Checkout