import React from "react";
import { NavLink } from "react-router-dom";
// import './../../../public/images/Term_Of_Use.pdf'
const Footer = () => {

  const openPdf = () => {
    // URL of the PDF
    const pdfUrl = './../../../images/Term_Of_Use.pdf';

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  };

  return (
<div className="global-aims-footer">
    <div className="container">

    <div className="row">
    <div className="col-6 col-md-3">
      <div className="globalaim">
      <img className="logo-edit1" src="/images/logoNew.svg" alt="no-data" />
        <p className="globalaimpara">
        Revolutionizing the online B2B marketplace, offering over 3,500 top-notch products and serving around 10,000 registered users worldwide.
        </p>
        <div className="supportemail">
        <img src="/images/LandingPg/line.svg" className="iconContact" />
          <p href="mailto:support@gmglobalenterprise.com" className="gm-email">
          info@flipdeals.com
          </p>
        </div>
        <div className="supportemail">
        <img src="/images/LandingPg/cl.svg" className="iconContact" />
          <div className="gm-email">
            <p href="tel:+971528943890" className="gm-number">
            +971-4-804-9888
            </p>
            {/* <p href="tel:+97145488781" className="gm-number">
              +97145488781
            </p> */}
          </div>
        </div>
        {/* <div className="supportemail">
          <i className="fas fa-landmark supportemailicon" />
          <p className="gm-email">
            Office No. 04-038, 4th Floor, Fahidi Heights Office Tower(Al Musalla
            Tower), Bur Dubai, Dubai-UAE, Dubai United Arab Emirates
          </p>
        </div> */}
      </div>
    </div>
    <div className="col-6 col-md-3">
      <div className="categories">
        <h2 className="categoriesheader">Company</h2>
        <div className="categoriesitems newLinks">
          <NavLink className="categoriesmaterials " to={"/allblogs"}>Blog </NavLink>
        </div>
        
        <div className="categoriesitems">
        <NavLink className="categoriesmaterials " to={"/Faq"}>FAQ </NavLink>

        </div>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Help Desk</a>
        </div>
        <div className="categoriesitems newLinks">
        <NavLink className="categoriesmaterials " to={"/ContactUs"}>Contact Us </NavLink>
        </div>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Full Store Directory</a>
        </div>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Become a Seller</a>
        </div>
      </div>
    </div>
    {/* <div className="col-6 col-md-2">
      <div className="categories">
        <h2 className="categoriesheader">Account</h2>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Log in</a>
        </div>
        <div className="categoriesitems">
          <a className="categoriesmaterials">Register</a>
        </div>
       
      </div>
    </div> */}
    <div className="col-6 col-md-3">
      <div className="categories">
        <h2 className="categoriesheader">Our Policies</h2>
        <div className="categoriesitems">
        <NavLink to={"/Shipping-policy"} className="categoriesmaterials ">Shipping Policy</NavLink>
        </div>
        <div className="categoriesitems newLinks">
          <NavLink to={"/Return-policy"} className="categoriesmaterials">Return Policy</NavLink>
        </div>
        <div  className="categoriesitems newLinks">
          <NavLink to={"/term-of-use"} className="categoriesmaterials ">Terms of Use</NavLink>
        </div>
        <div className="categoriesitems">
        <NavLink to={"/Warrenty-policy"} className="categoriesmaterials ">Warranty Policy</NavLink>
        </div>
     
     
        <div className="categoriesitems">
          <NavLink to={"/Privacy-policy"} className="categoriesmaterials">Privacy Policy</NavLink>
        </div>
        {/* <div className="categoriesitems">
          <a className="categoriesmaterials">Delivery</a>
        </div> */}
      </div>
    </div>
    <div className="col-6 col-md-3">
      <div className="categories">
        {/* <h2 className="categoriesheader">NEWSLETTER</h2> */}
        <div className="categoriesitems">
          <a className="SecuredPay">Secured Payment Gateways
          </a>
          <div className="visa">
          <img src="/images/LandingPg/master.png" className="visa-image" />
          <img src="/images/LandingPg/visaimg.png" className="visa-image" />
          <img src="/images/LandingPg/american.png" className="visa-image" />
          <img src="/images/LandingPg/pay.svg" className="visa-image" />
        </div>
        </div>
        <div className="categoriesicons">
          <a>
          <img src="/images/LandingPg/linkdin.svg" className=" iconTwitter" />
          </a>
          <a>
          <img src="/images/LandingPg/fb.svg" className=" iconTwitter" />
          </a>
          <a>
          <img src="/images/LandingPg/insta.svg" className=" iconTwitter" />
          </a>
   
          <a>
          <img src="/images/LandingPg/twitter.svg" className=" iconTwitter" />
          </a>

          <a>
          <img src="/images/LandingPg/tick.svg" className=" iconTwitter" />

          </a>
        </div>
        {/* <div className="categoriesimage">
          <div className="categorieimg">
            <a>
              <i className="fas fa-pen categoriespenicon" />
            </a>
          </div>
        </div> */}
     
      </div>
    </div>
  </div>
  <div className="footeraim">
    <p className="footerglobal">© 2024 <span className="BrandName">Flip Deals</span>. All rights reserved.</p>
  </div>
    </div>
</div>

  );
};

export default Footer;
