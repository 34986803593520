import React, { useEffect, useState } from 'react'
import Axios from "../../utils/AxiosConfi";
import ApiNames from '../../Constants/ApiUrls';
import { useNavigate } from 'react-router-dom';
const Orders = () => {
    const navigate = useNavigate();
    const [myOrdersList, setMyOrdersList] = useState([]);

    function addToCarts(product) {
        debugger
        let splitName =product?.title.replace(/\s+/g, "-");
        navigate(`/ProductView/${splitName}/${product?._id}`)
    }

    const getMyOrders = async () => {
        try {
            let api = ApiNames.getMyOrders
            const myOrders = await Axios.get(
                `${api}`
            );
            setMyOrdersList(myOrders.data)
        } catch (err) {
            console.log(err)
        }
    }

    function fixtheNuber(data) {
        if (data !== undefined && data !== null) {
          const numericData = parseFloat(data); // Convert input string to a number
          if (!isNaN(numericData)) { // Check if the conversion was successful
            const roundedValue = Math.round(numericData); // Round to nearest integer
            return roundedValue; // Format to two decimal places
          }
        }
        return ''; // Or any other default value or behavior you prefer
      }
    

    useEffect(() => {
        getMyOrders()
    }, [])


    function gotoOrderView(id) {
        navigate(`/Orderview/${id}`)
    }
    useEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

    return (
        <div className=" my-orderPage">
            <div className="container">
                <div className="filters-below">
                    <h1 className="home-url">
                        Home <span className="myOrder">/ My Orders</span>{" "}
                    </h1>

                    <div className="right-relevence">
                    </div>
                </div>
                {myOrdersList.length <= 0 ? (
                      <div style={{height:'80vh', display:'flex',justifyContent:'center',alignItems:'center'}} className=" my-orderPage">
                    <p className='NoItems'>Oops! It looks like you haven't placed any orders yet. </p>
                    <p className='NoItems'>Start shopping now to fill up your orders page!.</p>
                    </div>
                ) : (
                    <ul className="order-page-top list-unstyled">
                        {myOrdersList.map((order, index) => (
                            <li key={index} className="orders-status-below">
                                <p className="order-status">{order.deliveryStatus}</p>
                                {/* <p className="order-date">Delivery on 10 May 2023</p> */}
                                <div className="order-products-list">
                                    <div className="left-orderProduct">
                                        <div className="image-leftt">
                                            <img
                                                onClick={() => addToCarts(order.productDetails[0])}
                                                src={order.productDetails[0]?.thumbnail}
                                                className="OrderProduct-image"
                                                alt="..."
                                            />
                                        </div>

                                        <div className="product-textt">
                                            <p onClick={() => addToCarts(order.productDetails[0])} className="title-of-product">{
                                                order.productDetails[0]?.title
                                            }
                                            </p>
                                           <div className='costData'>
                                           <p className="buying-costs quanitytys">Delivery Type : {order?.deliveryMethod}</p>
                                           <p className="buying-costs quanitytys">Quantity : {order?.quantity} Q</p>
                                            <p className="buying-cost cost-to-cost">{fixtheNuber(order?.totalCost - order?.discountedPrice )} AED</p>
                                           </div>
                                        </div>
                                    </div>
                                    <div className="right-tracking-top">
                                        {/* <p className="GM-points">1035 Gm points</p> */}

                                        <div className="track-top">
                                            <div className="track-details">
                                                {order.status === 'CAPTURED' || order.status === 'cod' ? (
                                                    <div className="track-details">
                                                        <button className="Track-Order">TRACK ORDER</button>
                                                        {" "}
                                                        <h3 onClick={() => gotoOrderView(order._id)} className="detail-view">
                                                            View Product Deatils{" "}
                                                            <span>
                                                                <i className="fas fa-chevron-right arrow-right"></i>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                ) : (
                                                    <button className="Track-Order">Retry Payment</button>

                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}


            </div>
        </div>
    );
}


export default Orders;
