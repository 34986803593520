import React, { useState, useRef, useEffect } from "react";
import Axios from "../../utils/AxiosConfi";
import { NavLink } from "react-router-dom";
import { Modal, Offcanvas } from "react-bootstrap";
import ApiNames from "../../Constants/ApiUrls";
import Toaster from "../../utils/Toaster";
import encryptedData from "../../utils/crypto";
import { ToastContainer, toast } from 'react-toastify';
import { useCart } from "../../Context/CartContext";
import { useNavigate } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import { gapi } from "gapi-script";
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { FacebookLoginButton } from "react-social-login-buttons";
import ReactFacebookLogin from "react-facebook-login";
import FacebookLogin from 'react-facebook-login';
import { Swiper, SwiperSlide } from "swiper/react";
import MySkeleton from "../../Component/Loaders/MySkeleton";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
// import { Navigation,FreeMode } from "swiper/modules";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
const Header = () => {
  const clientId = '739075001051-ujn66ue64l3v0cs4gjv7ucpvilq3d8tr.apps.googleusercontent.com'
  const firstInputRef = useRef();
  const [timeRemaining, setTimeRemaining] = useState(1 * 60);
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const SideModalRef = useRef(null);
  const CartModalRef = useRef(null);
  const [show, setShow] = useState(false);
  const [mobileNumber, setPhoneNumber] = useState('');
  const [searchName, setSearchName] = useState('');
  const [getMobile, setMobile] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [getBrands, setBrands] = useState([]);
  const [getAllCateGoryInfo, setAllCateGoryInfo] = useState([]);
  const [getCollectionThree, setCollectionThree] = useState([]);
  const [getUserProfule, setUserProfile] = useState([]);
  const [getSearchItems, setSearchItems] = useState([]);
  const [getSubCategoryItems, setSubCategoryItems] = useState([]);
  const [getSelectedCategoryInformation, setSelectedCategoryInformation] = useState([]);
  const [getSelectedCategory, setSelectedCategors] = useState(false);
  const [searchResult, setSearchResults] = useState(false);
  const [getNumber, setsetNumber] = useState('971');
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [getFlag, setFlag] = useState({
    flag: 'iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBMjExNEYxQzE3OEExMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBMjExNEYxRDE3OEExMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEyMTE0RjFBMTc4QTExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEyMTE0RjFCMTc4QTExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VISheQAAAGRJREFUeNpiPCeo9p+BCGDUeYuBmoCJYYDAqMWjFtMMsBCr8H/c/98M7AzMQCYjfX38/QsjVD0jNTDRFv/592eEJS5mZuaBSVyM/LygMv0ftRIX8RYzMrKOFiCjFo9ajA8ABBgAlQMOS8Um6UkAAAAASUVORK5CYII='
  });
  const [getOtp, setOtp] = useState('');
  const [getOtpss, setOtpss] = useState('');
  const [isgetOtp, issetOtps] = useState(false);
  const inputRef = useRef(null);
  const inputRefTwo = useRef(null);
  const [isLoginUser, setIsLogin] = useState(null);
  const [getWishCount, setWishCount] = useState(0);
  const [getcartCount, setCartCount] = useState(0);
  const [getcountryList, SetcountryList] = useState([]);
  const [getName, setName] = useState('');
  const [getcartGrandTotal, setCartGrandTotalCount] = useState(0);
  const [getcarItems, setCartItems] = useState([]);

  const [showCart, setShowCart] = useState(false);

  const handleCloseCart = () => setShowCart(false);
  const handleShowCart = () => setShowCart(true);
  const handleToggleCart = () => setShowCart((prevShowCart) => !prevShowCart);

  const { cartItems, setSelectRefresh, selectRefresh, addToCart, whenLoginRefreshProduct, removeFromCart } = useCart()

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    }

    gapi.load('client:auth2', start);
  }, []); // Ensure the effect runs only once after the initial render


  function countTimer() {

    const interval = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;

    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  function getCateIngo(data) {
    debugger
    navigate(`/Filter/6/${data?.subCategoryName}/${data._id}`)
    handleMouseLeaveTooltip()
  }
  const onSuccess = async (res) => {
     
    let sendData = {
      profileObj: res.profileObj
    }
    let api = ApiNames.loginWithGoogle
    try {
      const response = await Axios.post(`${api}`, sendData);
      localStorage.setItem('userId', response.data.id)
      setShow(false)
      issetOtps(false)
      setIsLogin(true)
      localStorage.setItem('token', response.data.token)
      let getToken = await localStorage.getItem('token')
      notify(response.data.message)
      addToCart('')

      whenLoginRefreshProduct(response.data.id)
    } catch (error) {

      if (error.response.data.error) {
        Toaster(error.response.data.error)
      }
      if (error.response.data.message) {
        Toaster(error.response.data.message)
      }

      console.error('Error:', error);
    }

  };

  const onFailure = (res) => {
    console.log("Failure:", res);
  };

  const { signIn } = useGoogleLogin({
    clientId: clientId,
    onSuccess: onSuccess,
    onFailure: onFailure,
    isSignedIn: true,
  });

  const closeLoginModal = (e) => {
    setShow(false)
    setMobile('')
    issetOtps(false)
  }
  const onLogoutSuccess = () => {
    console.log("Logout Success");
  };

  const handleMouseEnterWithDelay = (item) => {
    // Set a timeout to call the handleMouseEnters function after 1 second
    const timeout = setTimeout(() => {
      handleMouseEnters(item);
    }, 100); // 1000 ms = 1 second

    // Store the timeout ID in the hoverTimeouts state, keyed by the item's id
    setHoverTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [item._id]: timeout,
    }));
  };
  const [hoverTimeouts, setHoverTimeouts] = useState({});
  const handleMouseLeaveWithClear = (item) => {
    // Clear the timeout if it exists
    if (hoverTimeouts[item._id]) {
      clearTimeout(hoverTimeouts[item._id]);
    }

    // Remove the timeout ID from the hoverTimeouts state
    setHoverTimeouts((prevTimeouts) => {
      const newTimeouts = { ...prevTimeouts };
      delete newTimeouts[item._id];
      return newTimeouts;
    });

    // Call the handleMouseLeaves function
    handleMouseLeaves();
  };

  const onLogoutFailure = () => {
    console.log("Logout Failure");
  };

  const { signOut } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onLogoutFailure,
  });

  const responseFacebook = () => {
    window.FB.init({
      appId: '2176615782713024',
      xfbml: true,
      cookie: true,
      version: 'v10.0' // Specify the version here
    });
  };

  const handleLogins = async () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
           
          console.log('Welcome! Fetching your information.... ',response);
          window.FB.api('/me', { fields: 'name,email,picture' }, async function (response) {
            console.log('Good to see you, ' + response.name + '.');
             
            let sendData = {
              email: response.email,
              name: response.name,
              profileImage: response.picture.data.url
            }
            // let sendData = {
            //   email:'irakammurali@gmail.com ',
            //   name:'Murali',
            //   profileImage:'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=7513493285431227&height=50&width=50&ext=1719398629&hash=AbYd-U3ufjuE4v2v6mUhwHQm'
            // }
            let api = ApiNames.loginWithFacebook
            try {
              const Apiresponse = await Axios.post(`${api}`, sendData);
               
              localStorage.setItem('userId', Apiresponse.data?.id)
              setShow(false)
              issetOtps(false)
              setIsLogin(true)
              notify(Apiresponse.data?.message)
              addToCart('')
              localStorage.setItem('token', Apiresponse.data?.token)
              whenLoginRefreshProduct(Apiresponse.data?.id)
            } catch (error) {
              console.log(error)


              console.error('Error:', error);
            }

          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  const [isSdkLoaded, setIsSdkLoaded] = React.useState(false);

  React.useEffect(() => {
    if (window.FB) {
      setIsSdkLoaded(true);
    } else {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: 'YOUR_APP_ID', // Replace with your Facebook App ID
          cookie: true,
          xfbml: true,
          version: 'v10.0'
        });
        setIsSdkLoaded(true);
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);

  const handleLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          console.log('Welcome! Fetching your information.... ');
          window.FB.api('/me', { fields: 'name,email,picture' }, function (response) {
            console.log('Good to see you, ' + response.name + '.');
            // Handle the response as per your requirements
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  const handleKeyUp = (event) => {
    setName(event.target.value)
    getProfileDetails(event.target.value)
  }


  const getProfileDetails = async (getName) => {
    try {

      let api = ApiNames.Inssearch
      const response = await Axios.post(
        `${api}`, { name: getName }
      );
      SetcountryList([])
      SetcountryList(response.data)
    } catch (error) {
      console.error("Error checking login status:", error);
    }
  }

  function autoINput() {
    inputRef.current.focus();

    // Set cursor position to the end of the input value
    const input = inputRef.current;
    getProfileDetails("")
    input.selectionStart = input.selectionEnd = input.value.length;
  }


  function autoINputNumber() {
    inputRefTwo.current.focus();

    // Set cursor position to the end of the input value
    const input = inputRefTwo.current;
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  useEffect(() => {
    const changeBgColor = () => {
      const navBarEdits = document.querySelectorAll('.header .nvbar-edit');
      const logoColor = document.querySelectorAll('.navbar-light .navbar-brand');
      const navColor = document.querySelectorAll('.header .nvbar-edit .nav-menu .navbar-nav .nav-item .menubar-items');
      if (navBarEdits.length > 0) {
        navBarEdits.forEach(navBarEdit => {
          navBarEdit.style.backgroundColor = "#fff"; // Ensure camelCase: backgroundColor
        });
      } else {
        console.log("No .nvbar-edit elements found");
      }

      navColor.forEach((color) => {
        color.style.color = "#000000"
      })
      logoColor.forEach((color) => {
        color.style.color = "#000000"
      })

    };

    // Call the function once the component mounts
    changeBgColor();
  }, []);

  // useEffect(() => {
  //   const replaceColor = () => {
  //     // Get all <link> tags in the document
  //     const linkTags = document.querySelectorAll('link[rel="stylesheet"]');

  //     // Iterate through each <link> tag
  //     linkTags.forEach(linkTag => {
  //       // Fetch the CSS file associated with the <link> tag
  //       fetch(linkTag.href)
  //         .then(response => response.text())
  //         .then(cssText => {
  //           // Replace occurrences of #02adc7 with red
  //           const updatedCssText = cssText.replace(/#02adc7/g, '#02adc7');

  //           // Create a new <style> tag and insert the modified CSS text
  //           const styleTag = document.createElement('style');
  //           styleTag.textContent = updatedCssText;

  //           // Replace the <link> tag with the new <style> tag
  //           linkTag.parentNode.replaceChild(styleTag, linkTag);
  //         })
  //         .catch(error => {
  //           console.error('Error fetching CSS:', error);
  //         });
  //     });
  //   };

  //   // Call the function once the component mounts
  //   replaceColor();
  // }, []); 

  useEffect(() => {
    const getProfileDetails = async () => {
      try {

        let api = ApiNames.profile
        const response = await Axios.get(
          `${api}`
        );
        setUserProfile(response.data)
        setFlag(response.data)
        setPhoneNumber(response.data.profile.mobile);
        setsetNumber(response.data?.profile?.countryCode)
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    }
    getProfileDetails()
  }, [])



  // useEffect(() => {
  //   const getProfileDetails = async () => {
  //     try {

  //       let api = ApiNames.Inssearch
  //       const response = await Axios.post(
  //         `${api}`, { name: getName }
  //       );
  //       SetcountryList([])
  //       SetcountryList(response.data)
  //     } catch (error) {
  //       console.error("Error checking login status:", error);
  //     }
  //   }
  //   getProfileDetails()
  // },[])

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getShowCategory
        const response = await Axios.get(
          `${url}`
        );

        setCategory(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);


  useEffect(() => {
    const getAllCategory = async () => {
      try {
        let url = ApiNames.getAllCategory
        const response = await Axios.get(
          `${url}`
        );

        setAllCategory(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllCategory();
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [clickCount, setClickCount] = useState(0);
  const [getCategory, setCategory] = useState([]);
  const [getAllCategorys, setAllCategory] = useState([]);

  const handleClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {


        let token = await localStorage.getItem('token');
        if (token) {
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        // Handle the error as needed
      }
    };

    checkLoginStatus();
  }, []);


  useEffect(() => {
    if (clickCount === 1) {
      // Handle single click action here
      const hamburger = document.querySelector(".hamburger-edit");
      const navMenu = document.querySelector(".nav-menu");
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    } else if (clickCount === 2) {
      // Handle double click action here
      setClickCount(0); // Reset click count
    }

    // Cleanup the click count after a delay
    const timeout = setTimeout(() => {
      setClickCount(0);
    }, 300);

    clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, [clickCount]);


  useEffect(() => {
    checkWishCOunt()
  }, [cartItems])


  function logOut() {
    localStorage.clear()
    sessionStorage.clear()
    signOut()
    setIsLogin(false)
    setWishCount(0)
    setCartCount(0)

    addToCart('')
    let item = {
      item: 1
    }
    whenLoginRefreshProduct(item)
    navigate(`/`)
    window.location.reload()
  }

  async function checkWishCOunt() {
    let sendData = {
      mobile: getMobile,
      countryCode: getOtp
    }
    let api = ApiNames.user_getWishListCount
    try {
      const response = await Axios.get(`${api}`);
      setWishCount(response.data.wishListCount)
      setCartCount(response.data.cartCount)
      setCartItems(response.data.cartItemDetails)
      setCartGrandTotalCount(response.data.grandTotal)
    } catch (error) {
      console.error('Error:', error);
    }

  }





  function getCart() {
    if (isLoginUser) {
      handleToggleCart()
    } else {
      setShow(true)
    }
  }

  function wishList() {
    if (isLoginUser) {
      navigate(`/WishItems`)
    } else {
      setShow(true)
    }
  }
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const goToRouteProfile = () => {
    if (isLoginUser) {
      navigate(`/Profile`)
    } else {
      setShow(true)
    }
  }
  const goToRouteOrders = () => {
    if (isLoginUser) {
      navigate(`/Orders`)
    } else {
      setShow(true)
    }
  }

  function gotoCart() {
    setShowCart(false)
    navigate(`/CartItems`)
  }
  function GetCode(value) {
    setFlag(value)
    setsetNumber(value?.callingCode)
    autoINputNumber()
  }
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === 'mobile') {
      setMobile(value)
    }
    if (name === 'otp') {
      setOtp(value)
    }
    if (name === 'otpss') {
      setOtpss(value)
    }
  };

  const notify = (data) => toast(data);

  async function sendToOtp() {
    setOtp('')
    setOtpss('')
    if (!getMobile) {
      notify("Mobile number required")
      return
    }
    if (getMobile.length > 15) {
      notify("Invalid Mobile Number")
      return
    }
    if (getMobile) {
      if (/^\d+$/.test(getMobile)) {
        console.log("Mobile number")
      } else {
        notify("Invalid mobile number. Only numbers are allowed.")
        return
      }
    }

    let sendData = {
      mobile: getMobile,
      countryCode: getNumber
    }
    //  let info = await encryptedData(JSON.stringify(sendData))
    //  let information ={key:info}
    let api = ApiNames.login_Url
    try {
      const response = await Axios.post(`${api}`, sendData);
      setTimeout(() => {
        if (firstInputRef.current) {
          firstInputRef.current.focus();
        }
      }, 100)
      setTimeRemaining(1 * 60)
      setInputs(['', '', '', '', '', '']);
      countTimer()
      issetOtps(true)

      localStorage.setItem('userId', response.data._id)
    } catch (error) {

      if (error.response.data.error) {
        Toaster(error.response.data.error)
      }
      if (error.response.data.message) {
        Toaster(error.response.data.message)
      }

      console.error('Error:', error);
    }
  }
  async function verifyOtp() {
    if (getOtpss.length !== 6) {
      notify("OTP limit 6 digits only")
      return
    }
    let userId = await localStorage.getItem('userId')
    let sendData = {
      _id: userId,
      otp: getOtpss
    }
    let apis = ApiNames.login_Verify
    try {
      const response = await Axios.post(`${apis}`, sendData);



      setShow(false)
      issetOtps(false)
      setIsLogin(true)
      notify(response.data.message)
      addToCart('')
      localStorage.setItem('token', response.data.token)
      whenLoginRefreshProduct(response.data._id)
      window.location.reload()
    } catch (error) {

      notify(error.response.data.error);
    }
  }

const clearSearch =()=>{
  document.dispatchEvent(new Event('searchClear'));
}




  const handleCustomEvent = () => {
    setShow(true)
    // Your function logic here
  };

  useEffect(() => {
    // Listen for the custom event when the component mounts
    document.addEventListener('customEvent', handleCustomEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('customEvent', handleCustomEvent);
    };
  }, []);


  const handleCustomSideEvent = () => {

    if (CartModalRef.current) {
      CartModalRef.current.click()
    }

  };

  useEffect(() => {
    // Listen for the custom event when the component mounts
    document.addEventListener('customSideEvent', handleCustomSideEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('customSideEvent', handleCustomSideEvent);
    };
  }, []);


  // Add Products to Cart

  function fixtheNuber(data) {
    if (data !== undefined && data !== null) {
      const numericData = parseFloat(data); // Convert input string to a number
      if (!isNaN(numericData)) { // Check if the conversion was successful
        const roundedValue = Math.round(numericData); // Round to nearest integer
        return roundedValue; // Format to two decimal places
      }
    }
    return ''; // Or any other default value or behavior you prefer
  }
  async function addToCartsProduct(productId, type, items,DeliveryType) {
    try {
      console.log(items)
      if (items == 1 && type == 0) {
        console.log("No Action")
        return
      }
      let api = ApiNames.addToCart;
      let body = {
        productId: productId,
        type: type,
        DeliveryType:DeliveryType
      };
      const response = await Axios.post(
        `${api}`, body
      );

      addToCart(response.data.message)
    } catch (error) {
      Toaster(error.response.data.error)

    }
  }



  function addToCarts(product) {

    setShowCart(false)
    SideModalRef.current.click()
    let splitName =product.productDetails[0]?.title.replace(/\s+/g, "-");
        navigate(`/ProductView/${splitName}/${product.productDetails[0]?._id}`)

  }


  function openNewTab(product) {
    debugger
    let splitName =product.productDetails[0]?.title.replace(/\s+/g, "-");
    window.open(`/ProductView/${splitName}/${product.productDetails[0]?._id}`, '_blank');
  }


  const handleSearch = async (event) => {
    try {
      let query = event.target.value
      setSearchName(query)
      const response = await Axios.get(
        `${ApiNames.searchItems}${query}`);
      setSearchItems(response.data)
      setSearchResults(true)
    } catch (err) {
      setSearchName('')
      setSearchResults(false)
    }
  }

  async function handleMouseEnter(data) {
    setSelectedCategory(data.firstDetail.categoryName)
    let api = `${ApiNames.getBrandByPCategoryId}?id=${data.firstDetail._id}`;

    const response = await Axios.get(
      `${api}`
    );

    setBrands(response.data)
  }

  const handleMouseLeave = () => {
    buttonRef.current.click();
  };


  function gotoCategory(productId) {
    navigate(`/Filter/1/${productId}`)
  };

  function gotoBrand(productId) {
    navigate(`/Filter/5/${productId}`)
  };
  async function selectedCategoryById(value) {

    setSelectedCategoryInformation(value)
    const response = await Axios.get(
      `${ApiNames.getSubcategoriesById}${value._id}`);

    setSubCategoryItems(response.data)
    setSelectedCategors(true)
  };
  async function ShowCollectionsThree() {
    try{
      const response = await Axios.get(
        `${ApiNames.ShowCollectionsThree}`)
  
      setCollectionThree(response.data || [])
    }catch(err){
      setCollectionThree([])
    }

  };

  useEffect(() => {
    ShowCollectionsThree()
  }, [])

  async function closeSideItms() {
    setSubCategoryItems(false)
    setSelectedCategors(false)
  }

  function gotoCategoryItems() {
    debugger
    navigate(`/Filter/${getSelectedCategoryInformation?.categoryName}/1/${getSelectedCategoryInformation._id}`)
    toggleMenu(false)
    setSelectedCategors(false)
  };

  function gotoSubCategoryItems(id,category) {
    navigate(`/Filter/${category}/6/${id}`)
    toggleMenu(false)
  };


  function navigateForProducts(id, type,category) {
    debugger
    navigate(`/Filter/${category}/${type}/${id}`)
    setTooltip(null)
  }

  // search bar responcive function////
  const [isSearchShow, setIsSearchShow] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    // Function to handle click events outside the search bar
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        // Clicked outside of the search bar, so close it
        setIsSearchShow(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);
  const toggleSearch = () => {
    setIsSearchShow(!isSearchShow); // Toggle the visibility state
  };




  // OTP SUBMIT



  const [inputs, setInputs] = useState(['', '', '', '', '', '']);
  const submitButtonRef = useRef(null);

  const handleKeyDown = (e, index) => {
    if (!/^[0-9]{1}$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Tab' && !e.metaKey) {
      e.preventDefault();
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
      const newInputs = [...inputs];
      newInputs[index] = '';
      setInputs(newInputs);
      if (index > 0) {
        const prevInput = e.target.previousElementSibling;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleInput = (e, index) => {
    const newInputs = [...inputs];
    newInputs[index] = e.target.value;
    setInputs(newInputs);

    if (e.target.value && index < inputs.length - 1) {
      const nextInput = e.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    if (!/^[0-9]{6}$/.test(text)) {
      return;
    }
    const digits = text.split('');
    setInputs(digits);
    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otp = inputs.join('');
    // setOtpss(otp)
    if (otp.length !== 6) {
      notify("OTP limit 6 digits only")
      return
    }
    let userId = await localStorage.getItem('userId')
    let sendData = {
      _id: userId,
      otp: otp
    }
    let apis = ApiNames.login_Verify
    try {
      const response = await Axios.post(`${apis}`, sendData);
      setShow(false)
      issetOtps(false)
      setIsLogin(true)
      notify(response.data.message)
      addToCart('')
      localStorage.setItem('token', response.data.token)
      whenLoginRefreshProduct(response.data._id)
      window.location.reload()
    } catch (error) {

      notify(error.response.data.error);
    }
    // Add your submission logic here
  };





  // OTP SUBMIT END///





  function gotoCollectionFilter(data) {
    navigate(`/Filter/4/${data?.firstCollectionDetail?._id}`)
  }

 const gotoPrivacy = () =>{
  setShow(false)
  navigate(`Privacy-policy`)
 }


  const [tooltip, setTooltip] = useState(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnters = async (item) => {

    const response = await Axios.get(
      `${ApiNames.getCategoryWiseSub_All}${item._id} `
    );
    setTooltip(item);
    setIsTooltipVisible(true);
    setAllCateGoryInfo(response.data)
  };

  const handleMouseLeaves = () => {
    setTimeout(() => {
      if (!isTooltipVisible) {
        setTooltip(null);
      }
    }, 300);
  };

  const handleMouseEnterHeader = () => {
    setTooltip(null);
  }

  const handleMouseEnterTooltip = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeaveTooltip = () => {
    setIsTooltipVisible(false);
    setTooltip(null);
  };
  const handleBackdropClick = () => {
    setIsTooltipVisible(false);
    setTooltip(null);
  };

  return (
    <div>
      <div onMouseEnter={handleMouseEnterHeader} className="header">
        <ToastContainer position="bottom-center" theme="dark" hideProgressBar={false} />

        <nav className="navbar navbar-expand-lg  navbar-light nvbar-edit ">
          <div className="container">
            <div className="d-flex align-items-center  ">
              <div onClick={toggleMenu} className="hamburger-edit me-3">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
              <NavLink onClick={clearSearch} className="navbar-brand logo-edit  d-block d-lg-none" to="/">
                <img className="logo-edit1" src="/images/logoNew.svg" alt="no-data" />
              </NavLink>
            </div>

            <ul className="navbar-nav  navIcons-top    d-flex  d-lg-none">
              <li onClick={toggleSearch} className="nav-item">
                <a className="nav-link menubar-items">
                  <img
                    src="/images/menubar/search.svg"
                    className="icooons"
                    alt=""
                  />
                </a>
              </li>
              <li onClick={wishList} className="nav-item">
                <a className="nav-link menubar-items">
                  <img
                    src="/images/menubar/Interface - Heart_01.svg"
                    className="icooons"
                    alt=""
                  />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link menubar-items" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                  <img
                    src="/images/menubar/bag-2-svgrepo-com.svg"
                    className="icooons"
                    alt=""
                  />
                </a>
              </li>
              {isLoginUser ? (
                //Profile Icons
                <li className="nav-item">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle login-menu"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/images/menubar/profile-svgrepo-com.svg"
                        className="icooons"
                        alt=""
                      />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end login-Ddown">
                      <li onClick={goToRouteProfile}>
                        <a className="dropdown-item profile-logout" href="#">
                          <img
                            src="/images/menubar/profile-svgrepo-com.svg"
                            className="icooons"
                            alt=""
                          />
                          Profile
                        </a>
                      </li>
                      <li onClick={goToRouteOrders}>
                        <a className="dropdown-item profile-logout " alt="...">
                          <img
                            src="/images/menubar/bag-2-svgrepo-com.svg"
                            className="icooons"
                            alt="..."
                          />
                          My Order
                        </a>
                      </li>
                      <li onClick={logOut}>
                        <a
                          className="dropdown-item profile-logout border-botom"
                          href="#"
                          alt="..."
                        >
                          <span className="logout-icon">
                            <i className="fas fa-sign-out-alt"></i>
                          </span>
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <li onClick={handleShow} className="nav-item">
                  <a className="nav-link menubar-items">
                    <img
                      src="/images/menubar/profile-svgrepo-com.svg"
                      className="icooons"
                      alt=""
                    />
                  </a>
                </li>
              )}
            </ul>
            <div className=" navbar-collapse nav-menu navlist-top">
              <NavLink onClick={clearSearch} className="navbar-brand logo-edit  d-none d-lg-flex" to="/">
                <img className="logo-edit1" src="/images/logoNew.svg" alt="no-data" />
              </NavLink>
              <Searchbar className={"main-search"} />


              {/* <div className="navbar-nav dropdown mr-auto navUl-edit  ">
              <form
                className="dropdown-toggle search-boxTop d-none d-lg-block"
                role="search"
                data-bs-toggle="dropdown"
              >
                <input

                  onKeyUp={handleSearch}
                  className="form-control me-2 search-inpBox"
                  type="search"
                  placeholder="What are you shopping for?"
                  aria-label="Search"
                />
                <img
                  src="/images/menubar/search.svg"
                  className="search-icon"
                  alt=""
                />
              </form>

              <ul className="dropdown-menu searchbar-box">

                {searchResult === true && searchName.length >= 3 ? (
                  <div>

                    {getSearchItems?.slice(0, 6).map((value, index) => (
                      <li onClick={() => addToCarts(value?._id)}
                        key={index} className="search-items">
                        <div className="search-ItemInside">
                          <div className="item-image-left">
                            <img
                              className="itemm-imge "
                              src={value?.thumbnail}
                              alt="..."
                            />
                          </div>
                          <p className="search-productText">{value?.title}</p>
                        </div>
                        <div className="price-right">
                          <p className="original-price">
                            <del>{value?.marketPrice} AED</del>
                          </p>
                          <p className="discounted-price">{value?.sellingPrice} AED</p>
                        </div>
                      </li>
                    ))}
                  </div>
                ) : (
                  null
                )}

              </ul>
            </div> */}




              <ul className="navbar-nav  d-none d-lg-flex ">

                <li onClick={wishList} className="nav-item">
                  <a className="nav-link menubar-items" href="#">
                    <img
                      src="/images/menubar/Interface - Heart_01.svg"
                      className="icooons"
                      alt=""
                    />
                    Wishlist({getWishCount})
                  </a>
                </li>
                <li className="nav-item">
                  <a ref={CartModalRef} className="nav-link menubar-items" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <img
                      src="/images/menubar/bag-2-svgrepo-com.svg"
                      className="icooons"
                      alt=""
                    />
                    Cart({getcartCount})
                  </a>
                </li>

                {
                  isLoginUser ? (

                    <li className="nav-item">
                      <div className="dropdown">
                        <button
                          className="btn btn-secondary dropdown-toggle login-menu"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/images/menubar/profile-svgrepo-com.svg"
                            className="icooons"
                            alt=""
                          />
                          Profile
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end login-Ddown">
                          <li onClick={goToRouteProfile}>
                            <a className="dropdown-item profile-logout" href="#">
                              <img
                                src="/images/menubar/profile-svgrepo-com.svg"
                                className="icooons"
                                alt=""
                              />
                              Profile
                            </a>
                          </li>
                          <li onClick={goToRouteOrders}>
                            <a
                              className="dropdown-item profile-logout "
                              alt="..."
                            >
                              <img
                                src="/images/menubar/bag-2-svgrepo-com.svg"
                                className="icooons"
                                alt="..."
                              />
                              My Order
                            </a>
                          </li>
                          <li onClick={logOut}>
                            <a
                              className="dropdown-item profile-logout border-botom"
                              href="#"
                              alt="..."
                            >
                              <span className="logout-icon">
                                <i className="fas fa-sign-out-alt"></i>
                              </span>
                              Sign Out
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>

                  ) : (
                    <li onClick={handleShow} className="nav-item">
                      <a className="nav-link menubar-items" >
                        <img
                          src="/images/menubar/profile-svgrepo-com.svg"
                          className="icooons"
                          alt=""
                        />
                        Login
                      </a>
                    </li>
                  )
                }

                <li className="nav-item becomeSeller">
                  <a className="nav-link menubar-items" >
                    <img
                      src="/images/LandingPg/seller.svg"
                      className="icooons"
                      alt=""
                    />
                    Become a Seller
                  </a>
                </li>

              </ul>
            </div>

          </div>
          {isSearchShow && (
            <div ref={searchRef} className="w-100">
              <Searchbar className={"smaller-search"} />
            </div>
          )}
        </nav>

        {/* <div className="bottom-nav d-none d-lg-block">
          <ul className="categories-top list-unstyled">
          {getCategory.map(product => (
            <li className="nav-item dropdown edit-NVItems">
              <a
                className="nav-link dropdown-toggle edit-home"
                href="/"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {product?.categoryName}<img className="down-arrow" src="/images/menubar/downArrow.svg" alt="icon"/>
              </a>
              <div
                className="dropdown-menu Drop-Menu"
                aria-labelledby="navbarDropdown"
              >
                <ul className="dropBox-Data">
                  <li className="edit-lists">
                    <button className="HeaderLogin-btn">login</button>
                  </li>
                  <li className="edit-lists">
                    <button className="OpnDemo-btn">Open demo</button>
                  </li>
                </ul>
                <ul className="dropBox-Data">
                  <li className="edit-lists">
                    <a className="dropdown-item Box-items " href="/">
                      Products{" "}
                    </a>
                  </li>
                  <li className="edit-lists">
                    <a className="dropdown-item Box-items" href="/">
                      Trading Acounts
                    </a>
                  </li>
                </ul>
              </div>
            </li>
              ))}
          </ul>
        </div> */}
        <Modal className="ModelHead" centered show={show} backdrop="static" onHide={handleClose}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>


            <div className="registration-form">
              <div className="form-register">
                <div onClick={closeLoginModal} className="closeDiv"> <img className="closeIocn" src="/images/closeIc.svg" /></div>
                {isgetOtp ? (
                  <div >
                    <div className="registration-head">
                      <h1 className=" registration-form-head">Mobile Phone Verification</h1>
                      {/* <p className="registration-form-para">Fill out the form below to register account.</p> */}
                    </div>
                    <div className=" container register-header">
                      {/* <div className="register-number-header">
                        <label htmlFor="" className="form-label">OTP</label>
                        <div className="registration-flag">
                          <input type="number" maxLength={6} name="otpss" onChange={handleChange} ref={inputRefTwo} className="form-control-input" placeholder="* * * * * *" required />
                        </div>
                      </div> */}
                      <form >
                        <div className="gridPassword gap-3">
                          {inputs.map((value, index) => (
                            <input
                              key={index}
                              type="text"
                              className="inputPassword"
                              value={value}
                              placeholder="*"
                              autoComplete="off"
                              maxLength={1}

                              ref={index === 0 ? firstInputRef : null}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onInput={(e) => handleInput(e, index)}
                              onFocus={handleFocus}
                              onPaste={handlePaste}
                              required
                            />
                          ))}
                        </div>
                      </form>
                      <div className="form-check">
                        <label className="timerLog timerRestart" onClick={() => issetOtps(false)}>Try New Number</label>

                        {timeRemaining == '0' ? (
                          <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
                            <label className="timerLog timerRestart" onClick={sendToOtp}>Resend OTP</label>
                          </div>

                        ) : (
                          <label className="timerLog"> {formatTime(timeRemaining)}</label>
                        )}
                        <p className="privacy-content">Your personal data will be used to support your experience throughout this website, to manage access to your account and for other purposes described in our privacy policy.<a onClick={gotoPrivacy} className="privacy-policy"> privacy policy</a></p>
                      </div>



                      <div className="submit-button">
                        <button type="button" onClick={handleSubmit} className="btn register-btn">Verify OTP</button>
                      </div>
                    </div>
                  </div>

                ) : (

                  <div >
                    <div className="registration-head">
                      <h1 className=" registration-form-head"> Login</h1>
                      {/* <p className="registration-form-para">Fill out the form below to register account.</p> */}
                    </div>
                    <div className=" container register-header">
                      <div className="register-number-header">
                        <label htmlFor="" className="form-label">Mobile Number</label>
                        <div className="registration-flag">
                          <div className="registration-number">
                            <div className="dropdown dropdown">
                              <button onClick={autoINput} className="dropdown-toggle dropdown-flag-button" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={`data:image/png;base64,${getFlag.flag}`} alt="" className="registration-img" />
                                <p className="register-number">+{getNumber}</p>
                              </button>
                              <ul className="dropdown-menu drop_List" aria-labelledby="dropdownMenuButton1">

                                <li className="dropdown-flag-list">
                                  <div className="dropdowninsideimage">
                                    <img alt="" className="registration-img" />
                                    <input ref={inputRef} onKeyUp={handleKeyUp} type="text" className="form-control" placeholder="Select Country Name" id="" aria-describedby="Numberhelp" />
                                  </div>
                                </li>
                                {getcountryList.map((value, index) => (
                                  <li key={index} onClick={() => GetCode(value)} className="dropdown-flag-list">
                                    <a className="dropdown-item">
                                      <img src={`data:image/png;base64,${value.flag}`} alt="" className="registration-image" />
                                      <span className="flag-span">{value?.name}</span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <input type="text" name="mobile" onChange={handleChange} ref={inputRefTwo} className="form-control-input" placeholder="Enter Mobile Number" id="" aria-describedby="Numberhelp" required="" />
                        </div>
                      </div>
                      {/* <button onClick={signIn}>Login with Google</button>
                      <button>
                        Login with Facebook
                      </button> */}

                      <div className=" form-check">
                        <p className="privacy-content">Your personal data will be used to support your experience throughout this website, to manage access to your account and for other purposes described in our privacy policy.<a onClick={gotoPrivacy}  className="privacy-policy"> privacy policy</a></p>
                      </div>
                      {/* <label className="login_st">Login with Google or Facebook</label> */}
                   { /*  <div className="SocialLogin">
                        <button onClick={signIn} className="loginGoogle"><img className="socialImg" src="/images/google.svg" /></button>
                        <button onClick={() => { responseFacebook(); handleLogins(); }} className="loginGoogle"><img className="socialImgFb" src="/images/facebook.svg" /></button>
                      </div>*/}
                      <div className="submit-button">
                        <button type="submit" onClick={sendToOtp} className="btn register-btn">Get OTP</button>
                      </div>
                    </div>
                  </div>

                )}
              </div>
            </div>







          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>


      </div>

      <div className="bottom-nav-container">
        <div className="bottom-nav">
          <div className="container">
            <div className="nav-content">
              <div className="category-inside">
                <div className="dropdown h-100">
                  <button
                    className="btn btn-secondary dropdown-toggle category-btn"
                    type="button"
                    onClick={toggleMenu}
                  >
                    <img
                      src="/images/menu.svg"
                      className="menu-icon"
                      alt="no-data"
                    />
                    All Categories
                  </button>
                </div>
              </div>

              <div className="dealsMenu-top">

                <Swiper
                  cssMode={true}
                  navigation={true}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Mousewheel, Keyboard]}
                  className="mySwiper custom-myswiper"
                  spaceBetween={0}
                  centeredSlides={true}
                >
                  {getAllCategorys.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      className="bottom-navItems"
                      // onClick={() => navigateForProducts(item._id, 1,item?.categoryName)}
                      onClick={() => handleMouseEnterWithDelay(item)}
                      onMouseLeave={() => handleMouseLeaveWithClear(item)}
                    >

                      {item.categoryName}

                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {tooltip && (
          <>
            <div className="backdrop" onClick={handleBackdropClick}></div>
            <div
              className="tooltip-box-below"
              onMouseEnter={handleMouseEnterTooltip}
              onMouseLeave={handleMouseLeaveTooltip}
            >
              <div className="boxinside-data">
                <div className="container">
                  <ul className="boxdata-list-top list-unstyled">
                    <li className="category-listss">
                      <h1 className="category-title">CATEGORY</h1>
                      <ul className="cate-list-top list-unstyled">

                        {getAllCateGoryInfo?.subcategoryList?.map((value, index) => (
                          <li key={index} className="cat-items" onClick={() => navigateForProducts(value._id, 6,value?.subCategoryName)} >{value?.subCategoryName || <MySkeleton />}</li>
                        ))}

                      </ul>
                    </li>
                    <li className="category-listss list2">
                      <h1 className="category-title">Top Brands</h1>
                      <div className="brands-top">
                        {getAllCateGoryInfo?.topBrands?.map((value, index) => (
                          <div key={index} onClick={() => navigateForProducts(value._id, 5,value?.name)} className="brand-imges"><img className="brand-logos" src={value.image} /></div>
                        ))}


                      </div>
                    </li>
                    <li className="category-listss list3">


                      <div onClick={() => getCateIngo(getAllCateGoryInfo?.subcategoryList[0])} className="product-imges">
                        {getAllCateGoryInfo?.subcategoryList[0]?.subthumbnail ? (
                          <img className="inner-product-imge" src={getAllCateGoryInfo?.subcategoryList[0]?.subthumbnail} />

                        ) : (
                          null
                        )}

                      </div>




                    </li>
                    <li className="category-listss list3">

                      {getAllCateGoryInfo?.subcategoryList[0]?.subthumbnail ? (
                        <div onClick={() => getCateIngo(getAllCateGoryInfo?.subcategoryList[1])} className="product-imges">
                          {getAllCateGoryInfo?.subcategoryList[1]?.subthumbnail ? (
                            <img className="inner-product-imge" src={getAllCateGoryInfo?.subcategoryList[1]?.subthumbnail} />

                          ) : (
                            null
                          )}
                        </div>

                      ) : null}




                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>


      {/* Header CartItems */}

      {/* <Offcanvas
        placement="end"
        show={showCart}
        onHide={handleCloseCart}
        className="cart-Itemlists"
        backdropClassName="red-backdrop"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="canvass-title">
            CART
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="cart-lists-body">
          <p className="total-prices">Item ({getcartCount}) <span className=" ms-auto">Total: {getcartGrandTotal} AED</span></p>
          <ul className="list-unstyled">

            {getcarItems.map((item, index) => (
              <li key={index} style={{ marginBottom: '10px' }} className="items-below">
                <ul className="list-unstyled d-flex w-100" >
                  <li onClick={() => addToCarts(item.productId)}>
                    <div className="Itemimage-left">
                      <img
                        className="cart-item-imag"
                        src={item?.productDetails[0]?.thumbnail}
                        alt="..."
                      />
                    </div>
                  </li>
                  <li className="w-100">
                    <div className="rightItem-content">
                      <h1 onClick={() => addToCarts(item.productId)} className="cart-ItemTitle">{item.productDetails[0]?.title}
                      </h1>
                      <p className="price">Price</p>
                      <p className="Cartiteem-price">{item.productPrice} AED</p>
                      <div className="remove-increment ">
                        <div className="left-increment">

                          <button onClick={() => addToCartsProduct(item.productId, 0, item.quantity)} className="addSubtract-btn">
                            <span>
                              <img src="/images/LandingPg/minuss.svg" className=" minusIcons" />
                            </span>
                          </button>

                          <div className="numer-of">{item.quantity}</div>
                          <button onClick={() => addToCartsProduct(item.productId, 1, item.quantity)} className="addSubtract-btn">
                            <span>     <img src="/images/LandingPg/pluss.svg" className="PlusIcons" /></span>
                          </button>
                        </div>


                        <div className="right-remove">
                          <button onClick={() => addToCartsProduct(item.productId, -1)} className="rmove-btn"><img src="/images/LandingPg/deleteIcons.svg" className="IconsImgsDelete" /></button>{" "}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

              </li>
            ))}
          </ul>
          <div>
            {getcartCount === 0 ? (
              <p style={{ textAlign: 'center', color: '#02adc7', fontFamily: 'Sf-M' }}>Empty Cart</p>
            ) : (
              <button onClick={gotoCart} className="viewCrt-btn">
                View Cart
              </button>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}






      {/* <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Toggle right offcanvas</button> */}

      <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">

        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Offcanvas right</h5>
          <button type="button" className="btn-close text-reset" ref={SideModalRef} data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="cart-Itemlists">
            <div className="cart-lists-body">
              <ul className="cartNameTItle">
                <li><span className="cartTitle">Cart</span></li>
                <li style={{ cursor: 'pointer' }} data-bs-dismiss="offcanvas" aria-label="Close">  <img src="/images/menubar/close.svg" className=" closeIcons" /></li>
              </ul>
              <p className="total-prices">Items ({getcartCount}) <span className=" ms-auto">Total: {getcartGrandTotal} AED</span></p>
              <ul className="list-unstyled">

                {getcarItems.map((item, index) => (
                  <li key={index} style={{ marginBottom: '10px' }} className="items-below">
                    <ul className="list-unstyled d-flex w-100" >
                      <li 
                        onClick={(e) => {
                          if (e.ctrlKey) {
                            openNewTab(item)
                          } else {
                            addToCarts(item);
                          }
                        }}
                     
                      
                      
                      >
                        <div className="Itemimage-left">
                          <img
                            className="cart-item-imag"
                            src={item?.productDetails[0]?.thumbnail}
                            alt="..."
                          />
                        </div>
                      </li>
                      <li className="w-100">
                        <div className="rightItem-content">
                          <h1  onClick={(e) => {
                          if (e.ctrlKey) {
                            openNewTab(item)
                          } else {
                            addToCarts(item);
                          }
                        }} className="cart-ItemTitle">{item.productDetails[0]?.title}
                          </h1>
                          <p className="price">Price</p>
                          <p className="Cartiteem-price">{fixtheNuber(item.productPrice)} AED</p>
                          <div className="remove-increment ">
                            <div className="left-increment">

                              <button onClick={() => addToCartsProduct(item.productId, 0, item.quantity,item?.DeliveryType)} className="addSubtract-btn">
                                <span>
                                  {/* <i className="fas fa-minus"></i> */}
                                  <img src="/images/LandingPg/minuss.svg" className=" minusIcons" />
                                </span>
                              </button>

                              <div className="numer-of">{item.quantity}</div>
                              <button onClick={() => addToCartsProduct(item.productId, 1, item.quantity,item?.DeliveryType)} className="addSubtract-btn">
                                <span>     <img src="/images/LandingPg/pluss.svg" className="PlusIcons" /></span>
                              </button>
                            </div>


                            <div className="right-remove">
                              <button onClick={() => addToCartsProduct(item.productId, -1)} className="rmove-btn"><img src="/images/LandingPg/deleteIcons.svg" className="IconsImgsDelete" /></button>{" "}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                  </li>
                ))}
              </ul>
              <div className="buttonsCart">
                {getcartCount === 0 ? (
                  <p style={{ textAlign: 'center', color: '#02adc7', fontFamily: 'Sf-M' }}>Empty Cart</p>
                ) : (
                  <button data-bs-dismiss="offcanvas" aria-label="Close" onClick={gotoCart} className="viewCrt-btn">
                    View Cart
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>









      <div className="fonCa">

        {isOpen && <div className="backdrop" onClick={toggleMenu}></div>}
        <div className={`off-canvas-menu ${isOpen ? 'open' : ''}`}>
          {getSelectedCategory === false ? (
            <div>
              <div className="toHeader">
                <ul className="toListing">
                  <li>  <p className="soptxt">Shop by Category</p></li>
                </ul>
                <ul className="toListing">
                  <li >
                    {/* <i onClick={toggleMenu} className="fas fa-times"></i> */}
                    <img onClick={toggleMenu} src="/images/menubar/close.svg" className="RightImg" />
                  </li>
                </ul>
              </div>
              <div className="menu-content">
                {getAllCategorys.map((value, index) => (
                  <div key={index} className="btn-group dropend" >
                    <div onClick={() => selectedCategoryById(value)} className="btn  dropDons">
                      <p className="textCate">{value.categoryName}</p>
                      {/* <i className="fas fa-long-arrow-alt-right"></i> */}
                      <img src="/images/menubar/right.svg" className="RightImgIcon" />
                    </div>

                  </div>
                ))}

              </div>
            </div>
          ) : (
            <div>
              <div className="toHeader">
                <ul className="toListing">
                  <li>  <p className="soptxt">{getSelectedCategoryInformation?.categoryName}</p></li>
                </ul>
                <ul className="toListing">
                  <li >                     <img onClick={toggleMenu} src="/images/menubar/close.svg" className="RightImg" />
                  </li>
                </ul>
              </div>
              <div className="menu-content">
                <div className="btn-group dropend" >
                  <div className="btn  dropDons">
                    <p className="textCate" onClick={closeSideItms}><img src="/images/menubar/back.svg" className="BackImg" /> Main menu</p>
                    <p onClick={gotoCategoryItems} className="viewAlBtn">View all</p>
                  </div>

                </div>
                {getSubCategoryItems.map((value, index) => (
                  <div key={index} className="btn-group dropend" >
                    <div onClick={() => gotoSubCategoryItems(value._id,value?.subCategoryName)} className="btn  dropDons">
                      <p className="textCate">{value.subCategoryName}</p>
                      {/* <i className="fas fa-long-arrow-alt-right"></i> */}
                    </div>

                  </div>
                ))}

              </div>
            </div>
          )}


        </div>
      </div>









    </div>
  );
};

export default Header;
