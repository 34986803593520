import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Axios from '../../utils/AxiosConfi';
import ApiNames from "../../Constants/ApiUrls";
import Addreview from "../../Component/Addreview/Addreview";
import { useNavigate, useParams, Link } from "react-router-dom";
import ProductItemsNew from "../../Component/ProductItems/ProductItemsNew";
import { useCart } from "../../Context/CartContext";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Lottie from "lottie-react";
import fileData from "./../../../src/welcome.json";
import Welcome from "../../Component/Loaders/Welcome";
import Pagination from "./../Pagination/Pagination";
import PaginatedItems from "./../Pagination/Pagination";
import CategorySlider from "../../Component/Slider/categorySlider";
import PaginationComponent from "./Pagination";
import Toaster from "../../utils/Toaster";




const Filter = () => {
  const { type, id } = useParams();
  const [priceRange, setPriceRange] = useState('Sort by Price');
  const [productsLoadText, setProductsLoadText] = useState('Please wait...');
  const [priceRangeVaue, setPriceRangeValue] = useState('Sort by Price');
  const [bestProducts, setBestProducts] = useState([]);
  const [getTotalProductCount, setTotalProductCount] = useState(0);
  const [selectedPeice, setSelectedPrice] = useState([]);
  const [getBannerData, setBannerData] = useState([]);
  const [getLimit, setLimit] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [selectedSub, setSelectedSub] = useState(null);
  const [isLoginUser, setIsLogin] = useState(null);
  const [onDataReturn, setonDataReturn] = useState('');
  const [getIsValidmoreButton, isValidmoreButton] = useState(true);
  const [getIsSort, seIsSort] = useState(false);
  const [tetIsPriceSort, setIsPriceSort] = useState(false);
  const navigate = useNavigate();
  const [getPriceProducts, setPriceProducts] = useState([]);
  const [getPriceDropd, setPriceDroped] = useState([]);
  const [getBannerImage, setBannerImage] = useState([]);
  const [getfilterList, setfilterList] = useState([]);
  const [getCategoryGroup, setCategoryGroup] = useState([]);
  const [getfilterRange, setfilterRange] = useState([]);
  const [selectPrice, setSelectPrice] = useState([]);
  const [getSort, setSort] = useState();
  const [getIsLoader, SetIsLoader] = useState(true);
  const [getfilterLoad, setfilterLoad] = useState(true);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [priceRanges, setPriceRanges] = React.useState([0, 0]);
  const [startPrice, setStartPrice] = useState();
  const [endPrice, setEndPrice] = useState();
  const [getPriceModify, setPriceModify] = useState();
  const [changePrice, setChangePrice] = useState([0, 0]);

  useEffect(() => {
    setOpenIndexes(getfilterList.map((_, index) => index));
  }, [getfilterList]);

  const toggleAccordion = (index) => {
    setOpenIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((i) => i !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  const handleSubClick = (sub) => {
    setSelectedSub(sub?._id); // Update selected subcategory
    navigateForProducts(sub?._id, 6, sub?.name); // Navigate with selected subcategory
  };



  function fixtheNuber(data) {
    if (data !== undefined && data !== null) {
      const numericData = parseFloat(data); // Convert input string to a number
      if (!isNaN(numericData)) { // Check if the conversion was successful
        const roundedValue = Math.round(numericData); // Round to nearest integer
        return roundedValue; // Format to two decimal places
      }
    }
    return ''; // Or any other default value or behavior you prefer
  }
  const { addToCart, whenLoginRefreshProduct, cartItems } = useCart()
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [id, type]);

  useEffect(() => {
    setfilterLoad(true)


    getProducts();
  }, [type, id, cartItems, selectedFilters]);

  const getProducts = async () => {
    const api = ApiNames.filterProducts; // Update this with your API endpoint
    try {
      const body = {
        type: type,
        id: id,
        sort: getSort,
        filters: selectedFilters,
        limit: pageFilter,
        minPrice: startPrice,
        maxPrice: endPrice,
        isSort: getIsSort

      };
      setfilterLoad(true)
      const response = await Axios.post(`${api}`, body);
      setBestProducts(response.data.products)
      debugger
      if (response.data.products.length === 0) {
        setProductsLoadText("No items found")
      }
      setTotalProductCount(response?.data?.totalProductsCount)
      getBanner(response.data.products[0]?.categoryId)
      setPriceProducts(response.data.products)
      SetIsLoader(false)
      setTimeout(() => {
        setfilterLoad(false)

      }, 500)
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const getBanner = async (id) => {

    const api = ApiNames.getCollectionByCategory;
    // let body = {
    //   id: id
    // }
    // const response = await Axios.post(`${api}`, body);
    // console.log("ddd", response.data)
    setBannerData(id)
  }

  useEffect(() => {
    const getProducts = async () => {
      const api = ApiNames.filtersList; // Update this with your API endpoint
      try {
        const body = {
          type: type,
          id: id,
          filters: selectedFilters,
          sort: priceRangeVaue,

        };
        debugger
        const response = await Axios.post(`${api}`, body);
        console.log(tetIsPriceSort)
        if (type === '7') {
          const filterGroupResponse = await Axios.get(`${ApiNames.getCategoryGroup}${id}`);
          console.log(filterGroupResponse.data)
          if (filterGroupResponse.data.length > 0) {
            setCategoryGroup(filterGroupResponse.data)
          }

        }
        if (tetIsPriceSort === false) {
          setfilterRange(response.data)
          setStartPrice(response.data?.minSellingPrice)
          setEndPrice(response.data?.maxSellingPrice)
        }

        console.log(response.data)
        setfilterList(response.data.filtersList)



        setSelectPrice(response.data?.maxSellingPrice)
        let priceing = {
          startPrice: response.data?.minSellingPrice,
          endPrice: response.data?.maxSellingPrice,
        }
        debugger
        // setPriceRanges((prevRanges) => [response.data?.minSellingPrice, response.data?.maxSellingPrice]);
        // setPriceRanges([response.data?.minSellingPrice, response.data?.maxSellingPrice]);
        setSelectedPrice(priceing)


      } catch (error) {
        console.error('Error:', error);
      }
    };

    getProducts();
  }, [type, id, cartItems, priceRangeVaue, selectedFilters]);






  async function fetFilterProducts(data) {
    debugger
    let isSort = false;


    // return
    // present am not using this below codes
    if (data === 'Sort By Price') {
      isSort = false;
      setPriceRange('Sort By Price');
      setSort(1)
      const updatedFilters = selectedFilters.map(filter => {
        // Add the new key-value pair pricerange here
        return {
          ...filter,
        };
      });

      // Now updatedFilters contains the original objects with the new key added
      setSelectedFilters(updatedFilters);
    }
    if (data === 'price_asc') {
      isSort = true;
      setPriceRange('Low to High');
      setSort(1)
      const updatedFilters = selectedFilters.map(filter => {
        // Add the new key-value pair pricerange here
        return {
          ...filter,
        };
      });

      // Now updatedFilters contains the original objects with the new key added
      setSelectedFilters(updatedFilters);
    }
    if (data === 'price_desc') {
      setPriceRange('Hight to Low');

      isSort = true;
      setSort(-1)
      const updatedFilters = selectedFilters.map(filter => {
        // Add the new key-value pair pricerange here
        return {
          ...filter
        };
      });

      // Now updatedFilters contains the original objects with the new key added
      setSelectedFilters(updatedFilters);
    }
    if (data === 'lowTohighRating') {

      // Check if startPrice and endPrice already exist in selectedFilters
      const existingStartPriceIndex = selectedFilters.findIndex(filter => filter.key === 'startRating');
      const existingEndPriceIndex = selectedFilters.findIndex(filter => filter.key === 'endRating');

      // If startPrice already exists, update its value, otherwise add it to selectedFilters
      if (existingStartPriceIndex !== -1) {
        selectedFilters[existingStartPriceIndex].value = 1;
      } else {
        selectedFilters.push({
          key: 'startRating',
          value: 1
        });
      }

      // If endPrice already exists, update its value, otherwise add it to selectedFilters
      if (existingEndPriceIndex !== -1) {
        selectedFilters[existingEndPriceIndex].value = 5;
      } else {
        selectedFilters.push({
          key: 'endRating',
          value: 5
        });
      }

      setSelectedFilters([...selectedFilters]);
    }
    seIsSort(isSort)
  }


  function setManualnpuPrice() {
    debugger
    let sendINfo = [getfilterRange?.minSellingPrice, getfilterRange?.maxSellingPrice]
    setPriceRanges([155, getfilterRange?.maxSellingPrice])
    console.log(sendINfo)
  }

  function setPriceRange1(data) {
    setPriceModify("yes")
    getProducts();

    const startPrice = data[0];
    const endPrice = data[1];
    setStartPrice(startPrice)
    setEndPrice(endPrice)
    let priceing = {
      startPrice: startPrice,
      endPrice: endPrice,
    }
    setSelectedPrice(priceing)
    // const filteredProducts = getPriceProducts.filter(product => product.sellingPrice >= startPrice && product.sellingPrice <= endPrice);
    // console.log(filteredProducts)
    // setBestProducts([])
    // setBestProducts(filteredProducts)
    // console.log(data);
    // setSelectPrice(data[1]);

    // // Check if startPrice and endPrice already exist in selectedFilters
    // const existingStartPriceIndex = selectedFilters.findIndex(filter => filter.key === 'startPrice');
    // const existingEndPriceIndex = selectedFilters.findIndex(filter => filter.key === 'endPrice');

    // // If startPrice already exists, update its value, otherwise add it to selectedFilters
    // if (existingStartPriceIndex !== -1) {
    //   selectedFilters[existingStartPriceIndex].value = data[0];
    // } else {
    //   selectedFilters.push({
    //     key: 'startPrice',
    //     value: data[0]
    //   });
    // }

    // // If endPrice already exists, update its value, otherwise add it to selectedFilters
    // if (existingEndPriceIndex !== -1) {
    //   selectedFilters[existingEndPriceIndex].value = data[1];
    // } else {
    //   selectedFilters.push({
    //     key: 'endPrice',
    //     value: data[1]
    //   });
    // }

    // setSelectedFilters([...selectedFilters]);
  }


  const clearAllFilters = () => {
    setSelectedFilters([]);
  };

  const handleFilterSelect = (filter, keyName) => {

    setSelectedFilters((prevFilters) => {
      const filterObj = { key: keyName, value: filter };
      const filterExists = prevFilters.some((item) => item.key === keyName && item.value === filter);

      if (filterExists) {
        // Filter is already selected, so remove it
        return prevFilters.filter((item) => !(item.key === keyName && item.value === filter));
      } else {
        // Filter is not selected, so add it
        return [...prevFilters, filterObj];
      }
    });
    console.log(selectedFilters)
  };


  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginate = pageNumber => {
    setCurrentPage(pageNumber);
  };


  const handleStartPriceChange = (event) => {
    setStartPrice(event.target.value);
  };

  const handleEndPriceChange = (event) => {
    setEndPrice(event.target.value);
  };

  const handleGoClick = () => {
    debugger

    setManualnpuPrice(startPrice, endPrice);
    let sendPrice = [startPrice, endPrice]

    setPriceRange1(sendPrice)

  };


  const loadProducts = async (num) => {
    debugger;
    // Increment limit value before making the API call
    const updatedLimit = num
    setLimit(updatedLimit); // Update the limit state asynchronously

    const api = ApiNames.filterProducts; // Update this with your API endpoint
    try {
      const body = {
        type: type,
        id: id,
        sort: getSort,
        filters: selectedFilters,
        limit: updatedLimit,
        minPrice: selectedPeice.startPrice,
        maxPrice: selectedPeice.endPrice

      };

      const response = await Axios.post(`${api}`, body);
      setBestProducts(response.data.products);

      getBanner(response.data.products[0]?.categoryId);
      setPriceProducts(response.data.products);
      isValidmoreButton(response?.data?.isValidmoreButton);


    } catch (error) {
      console.error('Error:', error);
    }
  };


  const getCurrentNumber = (num) => {
    console.log("num", num)
    setPageFilter(num)
    loadProducts(num)
  }





  async function addOrRemoverWishList(product) {
    try {
      const token = await localStorage.getItem('token'); // Retrieve token
      const isLoggedIn = !!token; // Determine login status

      if (isLoggedIn) {
        let sendData = {
          productId: product._id
        };

        const api = ApiNames.user_WishList;

        try {
          const response = await Axios.post(`${api}`, sendData);
          setonDataReturn(response.data.message); // Update message state
          addToCart(response.data.message); // Perform additional actions
        } catch (error) {
          console.error('Error:', error); // Log error for debugging
        }
      } else {
        // User not logged in: Handle appropriately
        console.warn('User not logged in');
        document.dispatchEvent(new Event('customEvent')); // Trigger custom event
      }
    } catch (error) {
      console.error('An error occurred while processing the wishlist:', error);
    }
  }


  async function addToCartsProduct(id, type) {
    try {
      let token = await localStorage.getItem('token');
      if (token) {
        let api = ApiNames.addToCart;
        let body = {
          productId: id,
          type: type
        };
        const response = await Axios.post(
          `${api}`, body
        );
        addToCart(response.data.message)
        Toaster(response.data.message)
        setTimeout(() => {
          document.dispatchEvent(new Event('customSideEvent'));
        }, 100)
      } else {
        document.dispatchEvent(new Event('customEvent'));
      }

    } catch (error) {

      Toaster(error?.responce?.data?.error)
      console.log(error);
    }
  }


  const handleSliderChange = (value) => {
    setStartPrice(value[0]);
    setEndPrice(value[1]);
    setIsPriceSort(true)
    // handleGoClick()
  };


  function navigateForProducts(id, type, category) {
    debugger
    navigate(`/Filter/${category.replace(/\s+/g, '-')}/${type}/${id}`)
  }


  return (
    <div style={{ minHeight: '100vh' }}>


      <div style={{ minHeight: '100vh' }}>
        <div className="filterSections-below">

          <div className="mid-products">
            <Addreview />

          </div>




        </div>
        <div style={{ padding: '0px 20px' }} className="row justify-content-center m-0 p-0">
          <div>
            <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" className="filterBtns"><i className="fas fa-filter" ></i>Filters</button>

          </div>
          <div className="col-md-3 filterSwich">

            <div className="filterSection">
              {/* <div className="filterByPrice">Filter by Price </div>
              <div className="filterByPrice">(Selected {fixtheNuber(selectedPeice.startPrice)} AED to {fixtheNuber(selectedPeice.endPrice)} AED)  </div> */}
              {/* <div>
                <p className="customisezPrice">Price (AED)</p>
                <div className="custmoseInpf">
                  <input
                    className="numberI"
                    value={startPrice}
                    onChange={handleStartPriceChange}
                    type="number"
                  />
                  <p className="toPrice">TO</p>
                  <input
                    className="numberI"
                    value={endPrice}
                    onChange={handleEndPriceChange}
                    type="number"
                  />
                  <p onClick={handleGoClick} className="toPriceGo">GO</p>
                </div>
              </div>
              <Slider
                className="range-slidess"
                range
                min={getfilterRange?.minSellingPrice} // Provide a fallback in case values are undefined
                max={getfilterRange?.maxSellingPrice} // Adjust the fallback as per your needs
                defaultValue={priceRanges}

                onChange={(value) => {
                  console.log(value)
                  setStartPrice(value[0])
                  setEndPrice(value[1])
                }
                }
                
              /> */}


              <div>
                <p className="customisezPrice">Price (AED)</p>
                <div className="custmoseInpf">
                  <input
                    className="numberI"
                    value={fixtheNuber(startPrice)}
                    onChange={handleStartPriceChange}
                    type="number"
                    min={getfilterRange?.minSellingPrice || 0}
                    max={endPrice}
                  />
                  <p className="toPrice">TO</p>
                  <input
                    className="numberI"
                    value={fixtheNuber(endPrice)}
                    onChange={handleEndPriceChange}
                    type="number"
                    min={startPrice}
                    max={getfilterRange?.maxSellingPrice || 1000}
                  />
                  <p onClick={handleGoClick} className="toPriceGo">
                    GO
                  </p>
                </div>
                <Slider
                  className="range-slidess"
                  range
                  min={getfilterRange?.minSellingPrice || 0}
                  max={getfilterRange?.maxSellingPrice || 1000}
                  value={[startPrice, endPrice]}
                  onChange={handleSliderChange}
                />
              </div>

              <div className="slidePrices">
                <label className="priceTxt">From:<span className="priceAccess"> {fixtheNuber(getfilterRange?.minSellingPrice)} AED </span> </label>
                <label className="priceTxt">To:  <span className="priceAccess">  {fixtheNuber(selectPrice)} AED </span> </label>
              </div>
              {/* {type === '7' ? ( */}
              <div className="categoryGroup">
                <p className="catTitle">Category</p>
                {getCategoryGroup.map((value) => (
                  <div style={{ padding: '4px' }}>
                    <p onClick={() => navigateForProducts(value?._id, 1, value?.name)} className="groupName">{value?.name}</p>
                    <div>
                      {value?.subcategories.map((sub) => (
                        <p
                          key={sub?._id}
                          onClick={() => handleSubClick(sub)}
                          className="groupSub"
                          style={{
                            // color: selectedSub === sub?._id ? "red" : "black", 
                            fontWeight: selectedSub === sub?._id ? "bold" : "100",
                            cursor: "pointer",
                          }}
                        >
                          {sub?.name}
                        </p>
                      ))}
                    </div>

                  </div>
                ))}

              </div>
              {/* ) : (
                null
              )} */}

              <div>
                <div className="accordionSection">
                  <div className="accordion accordionBox" id="accordionPanelsStayOpenExample">
                    {getfilterList.map((item, index) => (
                      <div className="accordion-item acd-items" key={index}>
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button acd-buttons ${openIndexes.includes(index) ? '' : 'collapsed'}`}
                            type="button"
                            onClick={() => toggleAccordion(index)}
                            aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
                          >
                            {item?.key}
                          </button>
                        </h2>
                        <div className={`accordion-collapse collapse ${openIndexes.includes(index) ? 'show' : ''}`}>
                          <div className="accordion-body acd-content">
                            {item?.values?.map((value, i) => (
                              <div key={i} className="form-check">
                                <input
                                  className="form-check-input form-check-Data"
                                  type="checkbox"
                                  value={value.value}
                                  id={value.value}
                                  checked={selectedFilters.some((filter) => filter.key === item.key && filter.value === value.value)}
                                  onChange={() => handleFilterSelect(value.value, item.key)}
                                />
                                <label className="form-check-label" htmlFor={value.value}>
                                  {value.value} ({value.count})
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div className="col-md-9">
            {/* <CategorySlider data={getBannerData} /> */}
            {bestProducts.length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100' }}>
                {/* <Welcome /> */}
                {productsLoadText}

              </div>
            ) : (
              <div>
                {/* <img className="bannerImg"  src={getBannerData?.bannerImg} /> */}

                <div className="selectedProducts">
                  {selectedFilters.length > 0 && (
                    <div>

                      <div className="selectedFilters">

                        {selectedFilters.map((filter, index) => (
                          <div key={index}>
                            <label onClick={() => handleFilterSelect(filter.value, filter.key)} className="filterName">
                              {filter.key}: {filter.value}
                              <span>
                                <img src="/images/menubar/closeIcn.svg" className="closeIcon" />
                              </span>
                            </label>
                          </div>
                        ))}
                        <label onClick={clearAllFilters} className="clearAllBtn">
                          <span>
                            <img src="/images/menubar/filter-x.svg" className="closeIcon" />
                          </span>
                          Clear Filters
                        </label>
                      </div>

                    </div>
                  )}
                  <div className="TotalProducts">

                    <label className="foundedProducts">We found <span className="counting">{getTotalProductCount}</span> items for you!</label>
                    <div>
                      <div className="right-relevence">
                        <Dropdown className="relvence-Ddown">
                          <Dropdown.Toggle variant="success" id="dropdown-basic" className="relvence-Inside">
                            <span className="upDwn-arrw">
                              <img src="/images/range.svg" className="ranges" />
                              <span className="relvance-filterTitle">{priceRange}</span>
                            </span>
                            <span className="upArrow-Icn">
                              <i className="fas fa-angle-down arwUpIcon"></i>
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="Relvence-Top">
                            <Dropdown.Item onClick={() => fetFilterProducts('Sort By Price')} className="relvence-Dropitems" >Sort By Price</Dropdown.Item>
                            <Dropdown.Item onClick={() => fetFilterProducts('price_asc')} className="relvence-Dropitems" >Price: Low to High</Dropdown.Item>
                            <Dropdown.Item onClick={() => fetFilterProducts('price_desc')} className="relvence-Dropitems" >Price: High to Low</Dropdown.Item>
                            {/* <Dropdown.Item onClick={() => fetFilterProducts('lowTohighRating')} className="relvence-Dropitems" >Rating: Low to High</Dropdown.Item>
                                     <Dropdown.Item onClick={() => fetFilterProducts('highTolowRating')} className="relvence-Dropitems" >Rating: High to Low</Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>


                      </div>
                    </div>
                  </div>
                  {getfilterLoad === true ? (
                    <div className="loaderMini">
                      <Welcome />

                    </div>
                  ) : (

                    <div>

                      {/* <ProductItemsNew productObj={bestProducts} /> */}

                      <div className="row row-cols-2 row-cols-md-5 g-3">
                        {bestProducts.map((product, index) => (
                          <div key={index} className="col filterProductItem">
                            <div className="card product-card">
                              <div className="card-top">
                                <div className="productImg-top">
                                  {product.quantity > 0 ? (
                                    <Link style={{ textDecoration: 'none' }} to={`/ProductView/${product?.title?.replace(/[\s/]+/g, "-")}/${product?._id}`}>
                                      {product?.thumbnail ? (
                                        <img
                                          src={product?.thumbnail}
                                          className="card-img-top product-cardImage"
                                          alt="..."
                                        />
                                      ) : (
                                        <div className="Imgloaders">
                                          <Lottie className="loadderFIle" animationData={fileData} loop={true} />
                                        </div>
                                      )}
                                    </Link>
                                  ) : (
                                    <Link style={{ textDecoration: 'none' }} to={`/ProductView/${product?.title?.replace(/[\s/]+/g, "-")}/${product?._id}`}>
                                      <p className="productSoldOut">
                                        <label className="lableStock">Out of Stock</label>
                                      </p>
                                      <img
                                        src={product?.thumbnail}
                                        className="card-img-top product-cardImage soldProducts"
                                        alt="..."
                                      />
                                    </Link>
                                  )}
                                </div>

                                <div>
                                  {product.isWishlist ? (
                                    <img
                                      onClick={() => addOrRemoverWishList(product)}
                                      src="/images/LandingPg/wishlist-fill.png"
                                      alt=".."
                                      className="wishlist-icon"
                                    />
                                  ) : (
                                    <img
                                      onClick={() => addOrRemoverWishList(product)}
                                      src="/images/LandingPg/wishlist-icon.png"
                                      alt=".."
                                      className="wishlist-icon"
                                    />
                                  )}
                                </div>
                              </div>
                              <Link style={{ textDecoration: 'none' }} to={`/ProductView/${product?.title?.replace(/[\s/]+/g, "-")}/${product?._id}`} className="card-body product-body">
                                <p

                                  className="card-title item-title"
                                >
                                  {product.title}
                                </p>
                                <div className="item-review">
                                  <div className="starss">
                                    {[1, 2, 3, 4, 5].map((rating) => (
                                      <span key={rating}>
                                        {product.ReviewRating >= rating ? '★' : '☆'}
                                      </span>
                                    ))}
                                  </div>
                                  <span className="custemer-rivew"> {product?.totalReviews}</span>
                                </div>
                                <p className="item-price">
                                  AED <span>{fixtheNuber(product.sellingPrice)}</span>
                                </p>
                                {product.quantity > 0 ? (
                                  <button
                                    onClick={() => addToCartsProduct(product._id, 1)}
                                    className="btn add-cart"
                                  >
                                    <img
                                      src="/images/LandingPg/bagIcn.svg"
                                      alt=".."
                                      className="bag-icon"
                                    />
                                    Add To Cart
                                  </button>
                                ) : (
                                  <button className="btn add-cart">Notify me</button>
                                )}
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>




                      <div className="moreProductsBtn">
                        <PaginationComponent totalCount={getTotalProductCount} onFunction={getCurrentNumber} />
                        {/* <p className="moreProductsBtn" >{getTotalProductCount}</p> */}

                      </div>

                      {/* <PaginatedItems itemsPerPage={3} items={bestProducts} />, */}
                    </div>

                  )}





                </div>

              </div>


            )}

          </div>
        </div>

      </div>



      <div className="offcanvas offcanvas-bottom" style={{ height: '70vh' }} tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          {/* <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5> */}
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body small">
          <div className="filterSection">
            <div className="filterByPrice">Filter by Price </div>
            <div className="filterByPrice">(Selected {selectedPeice.startPrice} AED to {selectedPeice.endPrice} AED)  </div>
            <div>
              <p className="customisezPrice">Price (AED)</p>
              <div className="custmoseInpf">
                <input
                  className="numberI"
                  value={startPrice}
                  onChange={handleStartPriceChange}
                  type="number"
                />
                <p className="toPrice">TO</p>
                <input
                  className="numberI"
                  value={endPrice}
                  onChange={handleEndPriceChange}
                  type="number"
                />
                <p onClick={handleGoClick} className="toPriceGo">GO</p>
              </div>
            </div>
            <Slider
              className="range-slidess"
              range
              min={getfilterRange?.minSellingPrice}
              max={getfilterRange?.maxSellingPrice}
              defaultValue={priceRanges}
              onChange={(value) => setPriceRange1(value)}
            />
            <div className="slidePrices">
              <label className="priceTxt">From:<span className="priceAccess"> {getfilterRange?.minSellingPrice} AED </span> </label>
              <label className="priceTxt">To:  <span className="priceAccess">  {selectPrice} AED </span> </label>
            </div>

            <div>
              <div className="accordionSection">
                <div className="accordion accordionBox" id="accordionPanelsStayOpenExample">
                  {getfilterList.map((item, index) => (
                    <div className="accordion-item acd-items" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button acd-buttons ${openIndexes.includes(index) ? '' : 'collapsed'}`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndexes.includes(index) ? 'true' : 'false'}
                        >
                          {item?.key}
                        </button>
                      </h2>
                      <div className={`accordion-collapse collapse  ${openIndexes.includes(index) ? 'show' : ''}`}>
                        <div className="accordion-body acd-content">
                          {item?.values?.map((value, i) => (
                            <div key={i} className="form-check">
                              <input
                                className="form-check-input form-check-Data"
                                type="checkbox"
                                value={value.value}
                                id={value.value}
                                checked={selectedFilters.some((filter) => filter.key === item.key && filter.value === value.value)}
                                onChange={() => handleFilterSelect(value.value, item.key)}
                              />
                              <label className="form-check-label" htmlFor={value.value}>
                                {value.value} ({value.count})
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default Filter;
